// Import necessary modules
import React, { useState, useEffect } from "react";
import "./CorsiTest.css";
import { API } from "../backend";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faHeart,
  faTrophy,
  faUser,
  faArrowLeft,
  faArrowRight,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import handIcon from "../images/hand-icon.png";

const CorsiTest = () => {
  const [blocks, setBlocks] = useState([]);
  const [sequence, setSequence] = useState([]);
  const [userSequence, setUserSequence] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isUserTurn, setIsUserTurn] = useState(false);
  const [message, setMessage] = useState("");
  const [score, setScore] = useState(0);
  const [speed, setSpeed] = useState(1000); // Default speed not set
  const [gameMode, setGameMode] = useState("regular");
  const [selectedSpeed, setSelectedSpeed] = useState("low"); // Track selected speed
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [showRetryPrompt, setShowRetryPrompt] = useState(false);
  const [scoreMultiplier, setScoreMultiplier] = useState(1); // Default multiplier
  const [sequenceLength, setSequenceLength] = useState(2); // Initial sequence length
  const [showInstructions, setShowInstructions] = useState(false);
  const [gridSize, setGridSize] = useState(3);
  const [lives, setLives] = useState(3);
  const [showPreLevelPage, setShowPreLevelPage] = useState(false);
  const [showPostLevelPage, setShowPostLevelPage] = useState(false);
  const [levelStatus, setLevelStatus] = useState("");
  const [isShowingSequence, setIsShowingSequence] = useState(false);
  const [isGamePaused, setIsGamePaused] = useState(false); // Controls game pauses
  const [currentStep, setCurrentStep] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [demoSequence, setDemoSequence] = useState([2, 5, 7]); // Example sequence
  const [currentDemoIndex, setCurrentDemoIndex] = useState(null);
  const [showHand, setShowHand] = useState(false);
  const [showGameOver, setShowGameOver] = useState(false); // New state to show the game over page

  const [triggerRepeat, setTriggerRepeat] = useState(false);

  const [theme, setTheme] = useState(
    () => localStorage.getItem("corsi-theme") || "dark"
  );

  const [showDescription, setShowDescription] = useState(true);
  const [showTutorial, setShowTutorial] = useState(() => {
    return localStorage.getItem("showTutorial") !== "false"; // Default is true unless set to false
  });

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("corsi-theme", newTheme);
  };

  useEffect(() => {
    document.body.classList.remove("corsi-dark-theme", "corsi-light-theme");
    document.body.classList.add(
      theme === "dark" ? "corsi-dark-theme" : "corsi-light-theme"
    );
  }, [theme]);

  const [futureShowTutorial, setFutureShowTutorial] = useState(showTutorial);

  const handleStartTutorial = () => {
    setShowDescription(false);
    setCurrentStep(1);
    setShowInstructions(true);
  };

  const handleTutorialChoice = (event) => {
    const shouldShow = event.target.checked;
    setFutureShowTutorial(shouldShow);
    localStorage.setItem("showTutorial", shouldShow); // Save preference for next time
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setIsFadingOut(true);
      setTimeout(() => {
        setCurrentStep((prev) => prev - 1);
        setIsFadingOut(false);
      }, 500);
    }
  };

  const repeatStep = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsFadingOut(false);
      setTriggerRepeat((prev) => !prev); // Toggle to trigger useEffect
    }, 500);
  };

  const nextStep = () => {
    if (currentStep < 5) {
      setIsFadingOut(true);
      setTimeout(() => {
        setCurrentStep((prev) => prev + 1);
        setIsFadingOut(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (currentStep === 1) {
      playDemoSequence(false); // Step 1: Show original flashing boxes
    } else if (currentStep === 2) {
      playDemoSequence(true); // Step 2: Show original sequence with hand clicks
    } else if (currentStep === 3) {
      playReverseDemoSequence(); // Step 3: Show reverse sequence
    }
  }, [currentStep, triggerRepeat]); // Include triggerRepeat

  const playDemoSequence = (withHand) => {
    setCurrentDemoIndex(null);
    let delay = 500; // Delay between each flash

    demoSequence.forEach((block, index) => {
      setTimeout(() => {
        setCurrentDemoIndex(block);
        setShowHand(withHand); // Show hand only in Step 2

        setTimeout(() => {
          setCurrentDemoIndex(null);
          setShowHand(false);
        }, 500); // Remove highlight after 500ms
      }, index * 1000 + delay);
    });
  };

  const playReverseDemoSequence = () => {
    setCurrentDemoIndex(null);
    let delay = 500;
    const reverseSequence = [...demoSequence].reverse(); // Reverse the sequence

    reverseSequence.forEach((block, index) => {
      setTimeout(() => {
        setCurrentDemoIndex(block);
        setShowHand(true);

        setTimeout(() => {
          setCurrentDemoIndex(null);
          setShowHand(false);
        }, 500);
      }, index * 1000 + delay);
    });
  };

  const startLevel = (status) => {
    setShowPreLevelPage(true);
    const newSequenceLength =
      status === "correct" ? sequenceLength + 1 : sequenceLength;

    setShowPreLevelPage(false);
    generateSequence(newSequenceLength);
    setUserSequence([]);
    setCurrentIndex(0);
    setSequenceLength(newSequenceLength);
  };

  // Updated `endLevel` function
  const endLevel = (status) => {
    setLevelStatus(status);
    setShowPostLevelPage(true);
    setTimeout(() => {
      setShowPostLevelPage(false);
      if (status === "correct") {
        startLevel("correct");
      } else {
        startLevel("wrong");
      }
    }, 2000);
  };

  const renderLives = () => {
    const heartColor = theme === "dark" ? "#edf2f5" : "#ff4d4d"; // Light mode uses red hearts
    const emptyHeartColor = theme === "dark" ? "gray" : "#dcdcdc"; // Light mode uses lighter gray for empty hearts

    return Array.from({ length: 3 }, (_, i) => (
      <FontAwesomeIcon
        key={i}
        icon={faHeart}
        className="heart"
        style={{ color: i < lives ? heartColor : emptyHeartColor }}
      />
    ));
  };

  useEffect(() => {
    const grid = [];
    for (let i = 0; i < gridSize * gridSize; i++) {
      grid.push({ id: i, isHighlighted: false, isCorrect: false });
    }
    setBlocks(grid);
  }, [gridSize]);

  const generateSequence = (length = sequenceLength) => {
    if (isGamePaused) return; // Prevent sequence generation when the game is paused

    const newSequence = [];
    for (let i = 0; i < length; i++) {
      newSequence.push(Math.floor(Math.random() * (gridSize * gridSize)));
    }
    // setSequence(newSequence);
    setSequence(
      gameMode === "reverse" ? [...newSequence].reverse() : newSequence
    );
    playSequence(newSequence);
  };

  const playSequence = async (seq) => {
    setMessage("به توالی توجه کنید...");
    setIsUserTurn(false); // Disable user input during sequence play

    for (let i = 0; i < seq.length; i++) {
      highlightBlock(seq[i]);
      await new Promise((resolve) => setTimeout(resolve, speed));
    }

    setTimeout(() => {
      setIsUserTurn(true); // Re-enable user input
      setMessage("نوبت شما: توالی را تکرار کنید.");
    }, 100);
  };

  const highlightBlock = (id) => {
    setBlocks((prevBlocks) =>
      prevBlocks.map((block) =>
        block.id === id
          ? { ...block, isHighlighted: true }
          : { ...block, isHighlighted: false }
      )
    );
    setTimeout(() => {
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => ({ ...block, isHighlighted: false }))
      );
    }, speed - 100);
  };

  const handleBlockClick = (id) => {
    if (!isUserTurn || isGamePaused) return; // Prevent interaction if the game is paused

    setUserSequence((prev) => {
      const updatedSequence = [...prev, id];
      highlightBlock(id);

      if (id !== sequence[updatedSequence.length - 1]) {
        // Wrong sequence
        showCorrectSequence(() => {
          setLives((prevLives) => {
            const newLives = prevLives - 1;
            if (newLives <= 0) {
              setMessage("❌ همه جونا تموم شد!");
              setShowGameOver(true);
              // endLevel("wrong");
              saveScoreToBackend(1, score);
            } else {
              setMessage("اشتباهه! هنوز جون داری!");
              endLevel("wrong");
              // saveScoreToBackend(score);
            }
            return newLives;
          });
        });
        return prev; // Do not proceed further
      }

      // Correct sequence so far
      if (updatedSequence.length === sequence.length) {
        const gridMultiplier = gridSize === 3 ? 1 : gridSize === 4 ? 1.5 : 2;
        const points = 10 * scoreMultiplier * sequence.length * gridMultiplier;
        setScore((prevScore) => prevScore + points);
        endLevel("correct"); // Proceed to the next level
      }

      return updatedSequence;
    });
  };

  const showCorrectSequence = (callback) => {
    setIsGamePaused(true); // Pause the game during correct sequence display

    sequence.forEach((id, index) => {
      setTimeout(() => {
        setBlocks((prevBlocks) =>
          prevBlocks.map((block) =>
            block.id === id
              ? { ...block, isCorrect: true }
              : { ...block, isCorrect: false }
          )
        );
      }, index * speed);
    });

    setTimeout(() => {
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => ({ ...block, isCorrect: false }))
      );
      setIsGamePaused(false); // Resume the game
      if (callback) callback(); // Execute the callback for post-sequence logic
    }, sequence.length * speed);
  };

  const resetGame = () => {
    setUserSequence([]);
    setCurrentIndex(0);
    setScore(0);
    setIsGameStarted(false);
    setLives(3);
    setSequenceLength(2);
    setShowRetryPrompt(false);
    setShowDescription(false);
    setShowInstructions(true);
    setShowGameOver(false);
    setCurrentStep(5);
  };

  const handleStartGameDesc = () => {
    setShowDescription(false);
    setShowInstructions(true);
    setCurrentStep(5);
  };

  const handleStartGame = () => {
    if (speed) {
      setShowInstructions(false);
      setIsGameStarted(true);
      generateSequence(2);
      startLevel();
    } else {
      setMessage("لطفاً یک سرعت برای شروع انتخاب کنید.");
    }
  };

  const handleSpeedChange = (selectedSpeed) => {
    const speedSettings = {
      low: { time: 1000, multiplier: 1 },
      medium: { time: 600, multiplier: 2 },
      high: { time: 300, multiplier: 3 },
    };
    setSpeed(speedSettings[selectedSpeed].time);
    setScoreMultiplier(speedSettings[selectedSpeed].multiplier);
    setSelectedSpeed(selectedSpeed);
  };

  const [isResizing, setIsResizing] = useState(false);

  const handleGridSizeChange = (size) => {
    setIsResizing(true);
    setGridSize(size);

    setTimeout(() => {
      setIsResizing(false); // Stop the resizing animation after it's done
    }, 500);
  };

  const saveScoreToBackend = async (
    gameId,
    score,
    movementTime = null,
    reactionTime = null
  ) => {
    try {
      const payload = { game_id: gameId };

      // Determine the correct payload based on game type
      if (movementTime !== null && reactionTime !== null) {
        payload.movement_time = movementTime;
        payload.reaction_time = reactionTime;
      } else {
        payload.score = score;
      }

      const response = await fetch(`${API}api/user/save_score/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + Cookies.get("token"),
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        console.error("Failed to save score:", response.statusText);
      } else {
        console.log("Score saved successfully!");
      }
    } catch (error) {
      console.error("Error saving score:", error);
    }
  };

  const [maxLevel, setMaxLevel] = useState(0);
  const [averageScore, setAverageScore] = useState(0);

  const fetchUserProgress = async () => {
    try {
      const response = await fetch(
        `${API}api/user/user_game_progress/?game_id=${1}`,
        {
          method: "GET",
          headers: {
            Authorization: "token " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch progress");

      const data = await response.json();
      setMaxLevel(data.max_level);
      setAverageScore(data.average_score);
    } catch (error) {
      console.error("Error fetching progress:", error);
    }
  };

  // Call fetchUserProgress() when the game ends
  useEffect(() => {
    if (showGameOver) {
      fetchUserProgress();
    }
  }, [showGameOver]);

  const totalSteps = 5;
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="game-page">
      <div className="corsi-test">
        <button className="corsi-theme-toggle" onClick={toggleTheme}>
          {theme === "dark" ? "🌞 حالت روشن" : "🌙 حالت تاریک"}
        </button>
        {showDescription && (
          <div className="game-description">
            <div className="game-description-page">
              <img
                src={require("../images/description-corsi.png")}
                alt="Wrong"
                className="corsi-result-image-desc"
              />
            </div>
            <p className="corsi-pre-level-text">
              در این بازی، شما باید ترتیب چشمک‌زدن جعبه‌ها را به خاطر بسپارید و
              سپس آن‌ها را به همان ترتیب کلیک کنید. با هر مرحله، ترتیب پیچیده‌تر
              می‌شود. اگر ۳ بار اشتباه کنید، بازی تمام خواهد شد!
            </p>

            {showTutorial && (
              <div className="tutorial-option">
                <label>
                  <input
                    type="checkbox"
                    checked={futureShowTutorial} // Uses future preference, not current session
                    onChange={handleTutorialChoice}
                  />
                  نمایش آموزش در دفعات بعدی
                </label>
              </div>
            )}

            {showTutorial ? (
              <button className="start-btn" onClick={handleStartTutorial}>
                شروع آموزش
              </button>
            ) : (
              <button className="start-btn" onClick={handleStartGameDesc}>
                رفتن به بازی
              </button>
            )}
          </div>
        )}
        {showInstructions && (
          <div className="instructions-container">
            <div className="corsi-header">
              <div className="stat">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div className="lives">{renderLives()}</div>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faTrophy} className="user-icon" />
                <span>{score}</span>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faChartSimple} className="user-icon" />
                <span>{sequenceLength}</span>
              </div>
            </div>
            {/* Left Side: Game Grid */}
            <div className="grid-preview">
              {currentStep === 4 ? (
                // Step 3: Show lose image and animation
                <div className="lose-image-container">
                  <img
                    src={require("../images/lost-corsi.png")}
                    alt="Wrong"
                    className="corsi-result-image-lose"
                  />
                </div>
              ) : (
                // Regular Grid Display
                <div className="grid-container">
                  <div
                    className={`grid-corsi ${
                      isResizing ? "grid-resizing" : ""
                    }`}
                    style={{ "--grid-size": gridSize }}
                  >
                    {[...Array(gridSize * gridSize)].map((_, i) => (
                      <div
                        key={i}
                        className={`block ${
                          i === currentDemoIndex ? "highlighted" : ""
                        }`}
                      >
                        {showHand && i === currentDemoIndex && (
                          <img
                            src={handIcon}
                            alt="Hand Click"
                            className="hand-animation"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Right Side: Step-by-Step Instructions */}
            <div className="instructions">
              {/* <h2>نحوه انجام بازی</h2> */}
              <div
                className={`instruction-box ${
                  isFadingOut ? "fade-out" : "fade-in"
                }`}
              >
                {currentStep === 1 && (
                  <p className="instruction-step">
                    ✅ جعبه‌ها روی صفحه ظاهر می‌شوند و به ترتیب چشمک می‌زنند.
                  </p>
                )}
                {currentStep === 2 && (
                  <p className="instruction-step">
                    🧠 ترتیب چشمک‌زدن را به خاطر بسپارید و سپس جعبه‌ها را به
                    همان ترتیب کلیک کنید.
                  </p>
                )}
                {currentStep === 3 && (
                  <p className="instruction-step">
                    🧠 ترتیب چشمک‌زدن را به خاطر بسپارید و سپس جعبه‌ها را به
                    ترتیب برعکس کلیک کنید.
                  </p>
                )}
                {currentStep === 4 && (
                  <p className="instruction-step">
                    ⚠️ اگر ۳ بار اشتباه کنید، بازی تمام می‌شود.
                  </p>
                )}
                {currentStep <= 4 && (
                  <button className="start-btn" onClick={nextStep}>
                    متوجه شدم
                  </button>
                )}
              </div>

              <div className="navigation-buttons">
                <button
                  className="nav-btn"
                  onClick={previousStep}
                  disabled={currentStep === 1}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="nav-btn repeat-btn" onClick={repeatStep}>
                  <FontAwesomeIcon icon={faRedo} />
                </button>
                <button
                  className="nav-btn"
                  onClick={nextStep}
                  disabled={currentStep >= 5}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>

              {currentStep < 5 ? (
                ""
              ) : (
                <div className="settings">
                  <button className="start-btn" onClick={handleStartGame}>
                    شروع بازی
                  </button>
                  <p>حالت بازی را انتخاب کنید:</p>
                  <div className="button-group">
                    <button
                      className={`grid-btn ${
                        gameMode === "regular" ? "selected" : ""
                      }`}
                      onClick={() => setGameMode("regular")}
                    >
                      معمولی
                    </button>
                    <button
                      className={`grid-btn ${
                        gameMode === "reverse" ? "selected" : ""
                      }`}
                      onClick={() => setGameMode("reverse")}
                    >
                      برعکس
                    </button>
                  </div>
                  <p>اندازه شبکه را انتخاب کنید:</p>
                  <div className="button-group">
                    <button
                      className={`grid-btn ${gridSize === 3 ? "selected" : ""}`}
                      onClick={() => handleGridSizeChange(3)}
                    >
                      ۳x۳
                    </button>
                    <button
                      className={`grid-btn ${gridSize === 4 ? "selected" : ""}`}
                      onClick={() => handleGridSizeChange(4)}
                    >
                      ۴x۴
                    </button>
                    <button
                      className={`grid-btn ${gridSize === 5 ? "selected" : ""}`}
                      onClick={() => handleGridSizeChange(5)}
                    >
                      ۵x۵
                    </button>
                  </div>
                  <p className="speed-text-choose">سرعت را انتخاب کنید:</p>
                  <div className="button-group">
                    <button
                      className={`speed-btn ${
                        selectedSpeed === "low" ? "selected" : ""
                      }`}
                      onClick={() => handleSpeedChange("low")}
                    >
                      کم
                    </button>
                    <button
                      className={`speed-btn ${
                        selectedSpeed === "medium" ? "selected" : ""
                      }`}
                      onClick={() => handleSpeedChange("medium")}
                    >
                      متوسط
                    </button>
                    <button
                      className={`speed-btn ${
                        selectedSpeed === "high" ? "selected" : ""
                      }`}
                      onClick={() => handleSpeedChange("high")}
                    >
                      زیاد
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {isGameStarted && showPreLevelPage && !showGameOver && (
          <div>
            <div className="corsi-header">
              <div className="stat">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div className="lives">{renderLives()}</div>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faTrophy} className="user-icon" />
                <span>{score}</span>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faChartSimple} className="user-icon" />
                <span>{sequenceLength}</span>
              </div>
            </div>
            <div className="corsi-pre-level-page">
              <img
                src={require("../images/start-corsi.png")}
                alt="Start"
                className="corsi-result-image"
              />
              <p className="corsi-pre-level-text">
                تعداد مربع‌ها: {sequenceLength}
              </p>
            </div>
          </div>
        )}

        {isGameStarted && showPostLevelPage && !showGameOver && (
          <div>
            <div className="corsi-header">
              <div className="stat">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div className="lives">{renderLives()}</div>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faTrophy} className="user-icon" />
                <span>{score}</span>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faChartSimple} className="user-icon" />
                <span>{sequenceLength}</span>
              </div>
            </div>
            <div className="corsi-post-level-page">
              {levelStatus === "correct" ? (
                <>
                  <img
                    src={require("../images/complete-corsi.png")}
                    alt="Correct"
                    className="corsi-result-image"
                  />
                  <h2 className="corsi-result-message">
                    درست بود! این مرحله رو گذروندی!
                  </h2>
                </>
              ) : (
                <>
                  <img
                    src={require("../images/uncomplete-corsi.png")}
                    alt="Wrong"
                    className="corsi-result-image"
                  />
                  <h2 className="corsi-result-message">
                    عیبی نداره! {lives} فرصت دیگه داری!
                  </h2>
                </>
              )}
            </div>
          </div>
        )}

        {isGameStarted &&
          !showPreLevelPage &&
          !showPostLevelPage &&
          showGameOver && (
            <div className="game-over-page">
              <img
                src={require("../images/uncomplete-corsi.png")} // Add your custom "Game Over" image here
                alt="Game Over"
                className="corsi-result-image"
              />
              <h2 className="corsi-result-message">
                بازی تمام شد! امتیاز شما: {score}
              </h2>
              <button onClick={resetGame} className="start-btn">
                شروع دوباره
              </button>
              {/* {showGameOver && (
                  <div className="game-over-page">
                      <h2>بازی تمام شد!</h2>
                      <p>بیشترین سطح طی شده: {maxLevel}</p>
                      <p>میانگین امتیاز: {averageScore}</p>
                      <button onClick={resetGame}>شروع دوباره</button>
                  </div>
              )} */}
            </div>
          )}

        {isGameStarted &&
          !showPreLevelPage &&
          !showPostLevelPage &&
          !showGameOver && (
            <>
              <div className="corsi-header">
                <div className="stat">
                  <FontAwesomeIcon icon={faUser} className="user-icon" />
                  <div className="lives">{renderLives()}</div>
                </div>
                <div className="stat">
                  <FontAwesomeIcon icon={faTrophy} className="user-icon" />
                  <span>{score}</span>
                </div>
                <div className="stat">
                  <FontAwesomeIcon icon={faChartSimple} className="user-icon" />
                  <span>{sequenceLength}</span>
                </div>
              </div>
              <div className="grid-container game">
                <div className="grid-corsi" style={{ "--grid-size": gridSize }}>
                  {blocks.map((block) => (
                    <div
                      key={block.id}
                      className={`block ${
                        block.isHighlighted ? "highlighted" : ""
                      } ${block.isCorrect ? "correct" : ""}`}
                      onClick={() => handleBlockClick(block.id)}
                    ></div>
                  ))}
                </div>
              </div>
              {showRetryPrompt && (
                <div className="retry-prompt">
                  <p>آیا می‌خواهید دوباره تلاش کنید؟</p>
                  <button onClick={resetGame}>بله</button>
                  <button onClick={() => setShowRetryPrompt(false)}>خیر</button>
                </div>
              )}
            </>
          )}
        <div className="progress-line-container">
          <div
            className="progress-line"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CorsiTest;
