import React from 'react'; 
import './Cogmo_Dash.css'; // CSS for styling

const Cogmo_Dash = ({}) => {
  // Sample user data
  const user = {
    photo: require("../images/Enhanced_Barat_RB.png"), // Replace with an actual path to an image in your public folder
    name: 'امین حاج برات',
    bio: 'یک سلامت‌مند 67 بهار دیده که قصد ارتقای تندرستی خود را دارد.',
    status: 'سلامت‌مند!',
  };
return (
    <div className="Cogmo-Dash-container">
      {/* Slogan Section */}
      <header className="Cogmo-Dash-header">
        <h1>همراه شما در مسیر سلامت‌مندی و نشاط!</h1>
      </header>

      {/* User Profile Section */}
      <div className="Cogmo-Dash-profile">
        <img
          src={user.photo || '/logo.png'}
          alt="User Avatar"
          className="Cogmo-Dash-profile-avatar"
        />
        <h2 className="Cogmo-Dash-user-name">{user.name || "User Name"}</h2>
        <p>{user.bio || "A brief bio about the user goes here."}</p>
        <div className="Cogmo-Dash-user-status">وضعیت شما: {user.status || "Feeling great today!"}</div>
      </div>

      {/* Game Interaction Section */}
      <div className="Cogmo-Dash-games">
        <h2 className='Cogmo-Dash-games-notice'>بازی و رقابت</h2>
        <div className="Cogmo-Dash-game-buttons">
          <button className="Cogmo-Dash-game-btn">بازی تقویت حافظه</button>
          <button className="Cogmo-Dash-game-btn">بازی 2</button>
          <button className="Cogmo-Dash-game-btn">بازی 3</button>
          <button className="Cogmo-Dash-game-btn">بازی 4</button>
        </div>
      </div>

      {/* Business Partner Logos */}
      <div className="Cogmo-Dash-partner-logos">
        <img src={require("../images/Logo_Salamatmand_Final2.png")} alt="Partner Logo 1" />
        <span className="Cogmo-Dash-logo-separator">|</span>
        <img src={require("../images/cogmo logo final_send.png")} alt="Partner Logo 2" />
      </div>

      {/* Recommendation Section */}
      <div className="Cogmo-Dash-recommendation-notice">
        <p>
        پیشنهاد می‌شود که در <strong>«یک ارزیابی سریع عملکرد حرکتی»</strong> به منظور دریافت برنامه آموزشی متناسب و شخصی شرکت کنید.
        </p>
        <button className="Cogmo-Dash-action-btn">انجام ارزیابی</button>
      </div>
    </div>
  );
};

export default Cogmo_Dash;