import React, { useState } from 'react';
import './Cogmo_Score.css'; // CSS for styling
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from 'react-chartjs-2'; // Chart.js library for diagrams
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopNavbar from '../components/Nav/TopNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Cogmo_Score = () => {
  const [currentChartIndex, setCurrentChartIndex] = useState(0);

  const user = {
    score: 200,
    rank: 1,
  };
  
  const gameScores = [
    {
      name: "بازی 1: تقویت حافظه",
      scores: [50, 80, 120, 150],
    },
    {
      name: "بازی 2: استروپ",
      scores: [30, 60, 100, 140],
    },
    {
      name: "بازی 3: Reflex Trainer",
      scores: [20, 50, 90, 130],
    },
    {
      name: "بازی 4: Strategy Master",
      scores: [40, 70, 110, 180],
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font:{
            size: '15',
            family: 'Calibri'
        }}
      },
      title: {
        display: true,
        text: "نمودار پیشرفت",
        font:{
          size: '20',
          family: 'Calibri'
      }
      },
    },
    scales: {
      x: {
        ticks:{
        font:{
          size: '13',
          family: 'Calibri',
          weight: 'Bold'
          }},
        grid: {
          color: "#e5e5e5",
        },
      },
      y: {
        ticks:{
          font:{
            size: '13',
            family: 'Calibri',
            weight: 'Bold'
            }},
        grid: {
          color: "#e5e5e5",
        },
      },
    },
  };

  const handlePrev = () => {
    setCurrentChartIndex((prev) => (prev === 0 ? gameScores.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentChartIndex((prev) => (prev === gameScores.length - 1 ? 0 : prev + 1));
  };

  const currentGame = gameScores[currentChartIndex];
  const labels = currentGame.scores.map((_, index) => `تلاش ${index + 1}`);
  const data = {
    labels,
    datasets: [
      {
        label: `پیشرفت بازی ${currentChartIndex + 1}`,
        data: currentGame.scores,
        borderColor: "rgba(255, 117, 140, 1)",
        backgroundColor: "rgba(255, 117, 140, 0.2)",
        borderWidth: 2,
        tension: 0.4,
        pointBackgroundColor: "rgba(255, 117, 140, 1)",
        pointBorderColor: "#ffffff",
        pointBorderWidth: 2,
      },
    ],
  };

  return (
    <>
    <TopNavbar/>
    <div className="Cogmo-Score-scoreboard-container">
      {/* User Info */}
      <div className="Cogmo-Score-user-info">
        <h2 className="Cogmo-Score-title">امتیاز و عملکرد</h2>
        <table className="Cogmo-Score-user-table">
          <thead>
            <tr>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>امتیاز نهایی</td>
              <td>{user.score}</td>
            </tr>
            <tr>
              <td>رتبه</td>
              <td>{user.rank}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Game Progress Chart */}
      <div className="Cogmo-Score-chart-container">
        <h3 className="Cogmo-Score-title">{currentGame.name}</h3>
        <div
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Line options={options} data={data} />
        </div>

        {/* Chart Navigation */}
        <div className="Cogmo-Score-chart-navigation">
          <button onClick={handleNext} className="Cogmo-Score-nav-button">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          <button onClick={handlePrev} className="Cogmo-Score-nav-button">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default Cogmo_Score;

