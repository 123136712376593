import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlask,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./TestResult.css";
import Footer from "../components/Sections/Footer";
import ProfileNavbar from "../components/Nav/ProfileNavbar";
import Cookies from "js-cookie";

const TestResult = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!Cookies.get("token")) {
      navigate("/");
    }
  }, []);

  const result = JSON.parse(localStorage.getItem("testResult"));

  if (!result) {
    return (
      <div className="test-result-no-result">
        <p>نتایج یافت نشد. لطفاً دوباره آزمایش خود را آپلود کنید.</p>
        <button
          className="test-result-upload-button"
          onClick={() => navigate("/")}
        >
          بازگشت به صفحه آپلود
        </button>
      </div>
    );
  }

  return (
    <>
      <ProfileNavbar />
      <div className="test-result-page">
        <header className="test-result-header">
          <h1 className="test-result-main-title">نتایج تحلیل آزمایش خون</h1>
        </header>
        <div className="test-result-content">
          <section className="test-result-section-summary">
            <h2 className="test-result-section-title">
              <FontAwesomeIcon icon={faCheckCircle} /> خلاصه نتایج
            </h2>
            <p className="test-result-item">{result.text_summary}</p>
            <p className="test-result-item">{result.general_result}</p>
          </section>
          <section className="test-result-section-table">
            <h2 className="test-result-section-title">
              <FontAwesomeIcon icon={faFlask} /> جزئیات پارامترها
            </h2>

            <div className="test-result-parameters">
              {result.table.map((param, index) => (
                <div key={index} className="test-result-parameter-card">
                  <h3 className="test-result-parameter-title">
                    {param.parameter}
                  </h3>
                  <div className="test-result-parameter-range">
                    <p>
                      مقدار:{" "}
                      {param.value !== "Not specified"
                        ? param.value
                        : "مشخص نشده"}
                    </p>
                    <p>
                      محدوده طبیعی:{" "}
                      {param.normal_range !== "Not specified"
                        ? param.normal_range
                        : "مشخص نشده"}
                    </p>
                  </div>
                  <p className="test-result-parameter-interpretation">
                    <h3 className="test-result-parameter-title">تفسیر:</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: param.interpretation }}
                      className="test-result-parameter-long-description"
                    />
                  </p>
                  <p className="test-result-parameter-recommendations">
                    <h3 className="test-result-parameter-title">توصیه ها</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: param.recommendations,
                      }}
                      className="test-result-parameter-long-description"
                    />
                  </p>
                </div>
              ))}
            </div>
          </section>

          <section className="test-result-section-disclaimer">
            <h2 className="test-result-section-title">
              <FontAwesomeIcon icon={faExclamationTriangle} /> توجه
            </h2>
            <p className="test-result-item">{result.disclaimer}</p>
          </section>
        </div>
        <footer className="test-result-footer">
          <button
            className="test-result-upload-button"
            onClick={() => navigate("/profile")}
          >
            بازگشت به داشبورد
          </button>
        </footer>
      </div>
      <Footer />
    </>
  );
};

export default TestResult;
