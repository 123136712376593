import React, { useState, useEffect, useRef } from "react";
import "./ReactionTest.css"; // Import the CSS file
import handIcon from "../images/hand-icon.png"; // Hand animation image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTrophy,
  faUser,
  faClock,
  faRedo,
  faHourglass,
  faStopwatch,
  faHourglassHalf,
  faHandPointUp,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { API } from "../backend";
import Cookies from "js-cookie";

const ReactionTest = () => {
  const [gameState, setGameState] = useState("waiting"); // "waiting", "holding", "ready", "finished", "foul"
  const [reactionTime, setReactionTime] = useState(null);
  const [movementTime, setMovementTime] = useState(null);
  const [isHolding, setIsHolding] = useState(false);
  const [foul, setFoul] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);

  const timeoutRef = useRef(null);
  const lowerBoxRef = useRef(null); // Reference to the lower box
  const colorChangeRef = useRef(null); // Store precise color change timestamp
  const movementStartRef = useRef(null); // Store movement start time

  const handleReactionType = (type) => {
    setReactionType(type);
  };

  const navigate = useNavigate();

  const [soundType, setSoundType] = useState("high");

  // Play sound function
  const playReactionSound = () => {
    if (soundType === "high") {
      highPitchSound.current.currentTime = 0; // Reset sound for instant replay
      highPitchSound.current.play();
    } else {
      lowPitchSound.current.currentTime = 0;
      lowPitchSound.current.play();
    }
  };

  const [showTutorial, setShowTutorial] = useState(() => {
    return localStorage.getItem("showTutorialReaction") !== "false"; // Default is true unless set to false
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [triggerRepeat, setTriggerRepeat] = useState(false);

  const [showHand, setShowHand] = useState(false);
  const [greenBox, setGreenBox] = useState(false);
  const [handPosition, setHandPosition] = useState("lower");

  const [reactionType, setReactionType] = useState("visual");

  useEffect(() => {
    if (currentStep === 1) {
      setShowHand(true); // Show hand in lower box
      setHandPosition("lower");
    }

    if (currentStep === 2) {
      setTimeout(() => {
        setGreenBox(true);
        setTimeout(() => {
          setHandPosition("upper"); // Move hand to upper box
        }, 1000);
      }, 2000);
    }
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const repeatStep = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsFadingOut(false);
      setTriggerRepeat((prev) => !prev); // Toggle to force animation reset
    }, 500);
  };

  const startGameAfterTutorial = () => {
    setShowTutorial(false);
  };

  const handleStartGameDesc = () => {
    setShowDescription(false);
    setShowInstructions(true);
    setCurrentStep(4);
  };

  useEffect(() => {
    const handleGlobalRelease = () => {
      if (isHolding) {
        if (gameState === "holding") {
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          setGameState("foul");
          setFoul(true);
          setIsHolding(false);
          return;
        }

        if (gameState === "ready" && colorChangeRef.current) {
          requestAnimationFrame(() => {
            setMovementTime(performance.now() - colorChangeRef.current);
          });
        }

        setIsHolding(false);
      }
    };

    document.addEventListener("mouseup", handleGlobalRelease);
    document.addEventListener("touchend", handleGlobalRelease);

    return () => {
      document.removeEventListener("mouseup", handleGlobalRelease);
      document.removeEventListener("touchend", handleGlobalRelease);
    };
  }, [isHolding, gameState]);

  const highPitchSound = useRef(null);
  const lowPitchSound = useRef(null);

  useEffect(() => {
    highPitchSound.current = new Audio("/Sounds/high.wav");
    lowPitchSound.current = new Audio("/Sounds/low.wav");

    // Preload the sounds
    highPitchSound.current.load();
    lowPitchSound.current.load();
  }, []);

  const startGame = () => {
    setGameState("holding");
    setReactionTime(null);
    setMovementTime(null);
    setFoul(false);
    setIsHolding(true);

    const delay = Math.floor(Math.random() * 3000) + 2000;

    timeoutRef.current = setTimeout(() => {
      setGameState("ready");
      if (reactionType === "auditory") {
        playReactionSound(); // Play sound when it's auditory reaction mode
      }
      colorChangeRef.current = performance.now(); // Store exact time when color changes
    }, delay);
  };

  const handleUpperClick = () => {
    if (gameState === "ready" && colorChangeRef.current) {
      requestAnimationFrame(() => {
        const newReactionTime = performance.now() - colorChangeRef.current;
        setReactionTime(newReactionTime);
        setGameState("finished");
        saveScoreToBackend(2, null, movementTime, newReactionTime);
      });
    }
  };

  const handleTouchMove = (event) => {
    if (isHolding && gameState === "holding") {
      const touch = event.touches[0];
      const box = lowerBoxRef.current.getBoundingClientRect();

      if (
        touch.clientX < box.left ||
        touch.clientX > box.right ||
        touch.clientY < box.top ||
        touch.clientY > box.bottom
      ) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        setGameState("foul");
        setFoul(true);
        setIsHolding(false);
      }
    }
  };

  const handleMouseLeaveLowerBox = () => {
    if (isHolding && gameState === "holding") {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setGameState("foul");
      setFoul(true);
      setIsHolding(false);
    }
  };

  const resetGame = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setGameState("waiting");
    setReactionTime(null);
    setMovementTime(null);
    setIsHolding(false);
    setFoul(false);
    colorChangeRef.current = null;
    movementStartRef.current = null;
  };

  const [theme, setTheme] = useState(
    () => localStorage.getItem("corsi-theme") || "dark"
  );

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("corsi-theme", newTheme);
  };

  useEffect(() => {
    document.body.classList.remove("corsi-dark-theme", "corsi-light-theme");
    document.body.classList.add(
      theme === "dark" ? "corsi-dark-theme" : "corsi-light-theme"
    );
  }, [theme]);

  const handleStartTutorial = () => {
    setShowDescription(false);
    setCurrentStep(1);
    setShowInstructions(true);
  };

  const handleTutorialChoice = (event) => {
    const shouldShow = event.target.checked;
    setShowTutorial(shouldShow);
    localStorage.setItem("showTutorialReaction", shouldShow); // Save preference for next time
  };

  const handleStartGame = () => {
    setShowInstructions(false);
    setShowDescription(false);
  };

  useEffect(() => {
    if (currentStep === 1) {
      setShowHand(true); // Show hand in lower box
      setHandPosition("lower"); // Ensure hand is in the lower position
      setGreenBox(false); // Ensure box is NOT green in step 1
    }

    if (currentStep === 2) {
      setShowHand(true); // Hand is still visible
      setHandPosition("lower"); // Reset hand position first
      setGreenBox(false); // Reset green box

      setTimeout(() => {
        setGreenBox(true); // Turn box green after 2 seconds
        setTimeout(() => {
          setHandPosition("upper"); // Move hand up AFTER the box turns green
          setTimeout(() => setShowHand(false), 2000);
        }, 500);
      }, 2000);
    }
  }, [currentStep, triggerRepeat]); // triggerRepeat ensures animations restart properly

  const saveScoreToBackend = async (
    gameId,
    score,
    movementTime = null,
    reactionTime = null
  ) => {
    try {
      const payload = { game_id: gameId };

      // Determine the correct payload based on game type
      if (movementTime !== null && reactionTime !== null) {
        payload.movement_time = movementTime;
        payload.reaction_time = reactionTime;
      } else {
        payload.score = score;
      }

      const response = await fetch(`${API}api/user/save_score/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + Cookies.get("token"),
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        console.error("Failed to save score:", response.statusText);
      } else {
        console.log("Score saved successfully!");
      }
    } catch (error) {
      console.error("Error saving score:", error);
    }
  };

  return (
    <div className="game-page">
      <div className="corsi-test">
        <button className="corsi-theme-toggle" onClick={toggleTheme}>
          {theme === "dark" ? "🌞 حالت روشن" : "🌙 حالت تاریک"}
        </button>
        {showDescription && (
          <div className="game-description">
            <div className="game-description-page">
              <img
                src={require("../images/description-corsi.png")}
                alt="Wrong"
                className="corsi-result-image-desc"
              />
            </div>
            <p className="corsi-pre-level-text">
              در این بازی، سرعت واکنش و زمان حرکت شما سنجیده می‌شود. روی جعبه
              پایینی کلیک کنید و نگه دارید تا جعبه بالایی سبز شود، سپس سریع آن
              را لمس کنید. هرچه سریع‌تر باشید، امتیاز بهتری می‌گیرید! اما اگر
              زودتر از تغییر رنگ رها کنید، خطا خواهید کرد. 🚀
            </p>

            {showTutorial && (
              <div className="tutorial-option">
                <label>
                  <input
                    type="checkbox"
                    checked={showTutorial} // Uses future preference, not current session
                    onChange={handleTutorialChoice}
                  />
                  نمایش آموزش در دفعات بعدی
                </label>
              </div>
            )}

            {showTutorial ? (
              <button className="start-btn" onClick={handleStartTutorial}>
                شروع آموزش
              </button>
            ) : (
              <button className="start-btn" onClick={handleStartGameDesc}>
                رفتن به بازی
              </button>
            )}
          </div>
        )}

        {showInstructions && (
          <div className="instructions-container">
            {/* Left Side: Game Grid */}
            <div className="grid-preview">
              {currentStep === 3 ? (
                // Step 3: Show lose image and animation
                <div className="lose-image-container">
                  <img
                    src={require("../images/lost-corsi.png")}
                    alt="Wrong"
                    className="corsi-result-image-lose"
                  />
                </div>
              ) : (
                <div className="game-grid">
                  <div
                    className={`reaction-test-box reaction-test-upper-box ${
                      greenBox ? "reaction-test-active" : ""
                    }`}
                  >
                    {greenBox}
                  </div>

                  <div className="reaction-test-spacer"></div>

                  <div className="reaction-test-box reaction-test-lower-box">
                    {showHand && (
                      <img
                        src={handIcon}
                        alt="Hand"
                        className={`hand-animation-reaction ${
                          handPosition === "upper" ? "move-up" : ""
                        }`}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Right Side: Step-by-Step Instructions */}
            <div className="instructions">
              {/* <h2>نحوه انجام بازی</h2> */}
              <div
                className={`instruction-box ${
                  isFadingOut ? "fade-out" : "fade-in"
                }`}
              >
                {currentStep === 1 && (
                  <p className="instruction-step">
                    ✅ انگشت خود را روی جعبه پایینی قرار دهید و نگه دارید. باید
                    آن را نگه دارید تا زمانی که جعبه بالایی تغییر رنگ دهد.
                  </p>
                )}
                {currentStep === 2 && (
                  <p className="instruction-step">
                    🕒 صبور باشید! جعبه بالایی پس از چند ثانیه به رنگ سبز تغییر
                    می‌کند. به محض سبز شدن، سریع انگشت خود را به سمت جعبه بالایی
                    حرکت دهید!
                    <br />
                    ⚠️ مهم:اگر زودتر از سبز شدن جعبه انگشت خود را رها کنید، خطا
                    محسوب می‌شود!
                  </p>
                )}
                {currentStep === 3 && (
                  <p className="instruction-step">
                    ⏳ زمان واکنش و سرعت حرکت شما ثبت خواهد شد. سعی کنید
                    سریع‌ترین واکنش را داشته باشید اما بدون خطا!
                    <br />
                    ⚡ زمان واکنش (RT): مدتی که از تغییر رنگ جعبه تا حرکت شما
                    طول می‌کشد.
                    <br />⚡ زمان حرکت (MT): مدت زمانی که طول می‌کشد تا دست از
                    جعبه پایینی به جعبه بالایی برسد.
                  </p>
                )}
                {currentStep <= 3 && (
                  <button className="start-btn" onClick={nextStep}>
                    متوجه شدم
                  </button>
                )}
              </div>

              <div className="navigation-buttons">
                <button
                  className="nav-btn"
                  onClick={previousStep}
                  disabled={currentStep === 1}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="nav-btn repeat-btn" onClick={repeatStep}>
                  <FontAwesomeIcon icon={faRedo} />
                </button>
                <button
                  className="nav-btn"
                  onClick={nextStep}
                  disabled={currentStep >= 4}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>

              {currentStep < 4 ? (
                ""
              ) : (
                <div className="settings">
                  <button className="start-btn" onClick={handleStartGame}>
                    شروع بازی
                  </button>
                  <p>نوع تریگر:</p>
                  <div className="button-group">
                    <button
                      className={`grid-btn ${
                        reactionType === "visual" ? "selected" : ""
                      }`}
                      onClick={() => handleReactionType("visual")}
                    >
                      دیداری
                    </button>
                    <button
                      className={`grid-btn ${
                        reactionType === "auditory" ? "selected" : ""
                      }`}
                      onClick={() => handleReactionType("auditory")}
                    >
                      شنیداری
                    </button>
                  </div>

                  {reactionType === "auditory" && (
                    <div>
                      <p>نوع صدا:</p>
                      <div className="button-group">
                        <button
                          className={`grid-btn ${
                            soundType === "high" ? "selected" : ""
                          }`}
                          onClick={() => setSoundType("high")}
                        >
                          زیر
                        </button>
                        <button
                          className={`grid-btn ${
                            soundType === "low" ? "selected" : ""
                          }`}
                          onClick={() => setSoundType("low")}
                        >
                          بم
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {!showDescription && !showInstructions && (
          <div className="actual-game-reaction">
            <div className="corsi-header">
              <div className="stat">
                <FontAwesomeIcon icon={faHourglassHalf} className="user-icon" />
                <span>
                  {reactionTime ? reactionTime.toFixed(2) + "ms" : "-"}
                </span>
              </div>
              <div className="stat">
                <FontAwesomeIcon icon={faStopwatch} className="user-icon" />
                <span>
                  {movementTime ? movementTime.toFixed(2) + "ms" : "-"}{" "}
                </span>
              </div>
            </div>
            <div
              className={`reaction-test-box reaction-test-upper-box ${
                gameState === "ready" ? "reaction-test-active" : ""
              }`}
              onClick={handleUpperClick}
            >
              {gameState === "ready" ? "بزن" : ""}
            </div>

            <div className="reaction-test-spacer"></div>

            <div
              ref={lowerBoxRef}
              className={`reaction-test-box reaction-test-lower-box ${
                isHolding ? "reaction-test-holding" : ""
              }`}
              onMouseDown={startGame}
              onTouchStart={startGame}
              onMouseLeave={handleMouseLeaveLowerBox}
              onTouchMove={handleTouchMove}
            >
              {isHolding || gameState === "ready" ? (
                ""
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faHandPointUp}
                    className="pointer-icon"
                  />{" "}
                  منو نگه دار
                </>
              )}
            </div>

            {!foul && (
              <button
                className="start-btn"
                onClick={() => (window.location.href = "/profile")}
              >
                بسه، خسته شدم!
              </button>
            )}

            {foul && (
              <div className="reaction-test-results reaction-test-foul">
                <p className="try-again-text">
                  خطا! از کادر خارج شدی یا زود انگشتت رو رها کردی!
                </p>
                <button className="start-btn" onClick={resetGame}>
                  دوباره
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReactionTest;
