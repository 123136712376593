import React, { useState } from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ServiceImage1 from "../../images/service7.jpeg";
import ServiceImage2 from "../../images/service9.jpeg";
import ServiceImage3 from "../../images/service6.jpeg";
import ServiceImage4 from "../../images/service12.jpeg";
import Signup from "../../screens/Signup";
import Popup from "../../screens/packaging";
import Packaging from "../../screens/packaging";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // Make sure you import the i18n instance
import Tours from "../../screens/Tours";

export default function Services({ toursRef }) {

  const [showLogin, setShowLogin] = useState(false);

  const handleShowLogin = () => {
    setShowLogin(true);
  };

  const handleHideLogin = () => {
      setShowLogin(false);
  };

  const [showPackaging, setShowPackaging] = useState(false);

  const handleShowPackaging = () => {
    setShowPackaging(true);
  };

  const handleHidePackaging = () => {
    setShowPackaging(false);
  };
  
  const { t } = useTranslation();
  const directionClass = i18n.language === 'en' ? 'ltr-i18n' : '';
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "10px 0" }}>
        <div className={`services-div container  ${directionClass}`}>
          <HeaderInfo>
            <p className="font40 extraBold">{t('NavbarService')}</p>
            <p className="font13">         
              {t('ServicesDescFirst')}
              <br />
              {t('ServicesDescSecond')}
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title={t('ServicesReserveTitle')}
                subtitle={t('ServicesReserveDesc')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title={t('ServicesConsultTitle')}
                subtitle={t('ServicesConsultDesc')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title={t('ServicesAITitle')}
                subtitle={t('ServicesAIDesc')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" 
              title={t('ServicesMoreTitle')} 
              subtitle={t('ServicesMoreDesc')}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
              <div className={`header-div ${directionClass}`}>
                <h4 className="font15 semiBold">{t('StartTravelTitle')}</h4>
                <h2 className="font40 extraBold">{t('StartTravelSmallDesc')}</h2>
                <p className="header-text font12">
                {t('StartTravelDesc')}
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div onClick={handleShowPackaging} style={{ width: "190px" }}>
                    <FullButton title={t('Packages')} />
                  </div>
                  <div style={{ width: "190px", marginRight: "15px", marginLeft: "15px" }}>
                    <FullButton title={t('Callus')} border />
                  </div>
                </ButtonsRow>
                </div>

              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={ServiceImage1} alt="service1" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={ServiceImage2} alt="service2" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={ServiceImage3} alt="service3" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={ServiceImage4} alt="service4" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
            <div ref={toursRef}>
              <Tours />
            </div>
          </div>
        </div>
      </div>
      {showLogin && <Signup handleClose={handleHideLogin}/>}
      {showPackaging && <Packaging handleClose={handleHidePackaging} />}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;

  /* Remove margin-right from the first box */
  &:first-child {
    margin-right: 0;
  }
  &:last-child {
    margin-left: 0;
  }

  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
    margin-right: 0; /* Ensure no margin-right on smaller screens */
  }
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 0;
  margin-top: 80px;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;