import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./FallRisk.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
  faPills,
  faBrain,
  faEye,
  faToilet,
  faWheelchair,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { API } from "../backend";
import Cookies from "js-cookie";

const FallRisk = () => {
  const [userName, setUserName] = useState("");
  const [history, setHistory] = useState([]); // Stores {question, answer} pairs
  const [currentQuestion, setCurrentQuestion] = useState(-1); // Start from -1 to wait for the name
  const [riskLevel, setRiskLevel] = useState("");
  const [loading, setLoading] = useState(false);

  // Ref for chat container
  const chatBodyRef = useRef(null);

  const riskIcons = {
    "ریسک کم": (
      <FontAwesomeIcon
        icon={faCheckCircle}
        style={{ color: "green", marginRight: "8px" }}
      />
    ),
    "ریسک متوسط": (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        style={{ color: "orange", marginRight: "8px" }}
      />
    ),
    "ریسک بالا": (
      <FontAwesomeIcon
        icon={faTimesCircle}
        style={{ color: "red", marginRight: "8px" }}
      />
    ),
  };

  const questions = [
    {
      text: (userName) =>
        `سلام ${userName} عزیز! به ارزیاب ریسک زمین خوردن خوش آمدید. ما چند سوال کوتاه از شما می‌پرسیم تا سطح ریسک شما را مشخص کنیم.`,
      icon: faQuestionCircle,
      isIntro: true, // Special flag for introduction
    },
    {
      text: "آیا بیش از 4 (چهار) عدد دارو در روز مصرف می‌کنید؟",
      icon: faPills,
    },
    {
      text: "آیا شما گاهی اوقات احساس عدم تمرکز(گیجی) یا بی‌قراری دارید؟",
      icon: faBrain,
    },
    {
      text: "آیا اختلال بینایی شما در حدی می‌باشد که باعث اختلال عملکرد روزانه شما شده باشد؟",
      icon: faEye,
    },
    {
      text: "آیا نیاز به استفاده مکرر از سرویس بهداشتی دارید؟",
      icon: faToilet,
    },
    {
      text: "آیا برای جابجایی نیار به وسیله کمکی دارید؟",
      icon: faWheelchair,
    },
    {
      text: "آیا شما فراموشکار می‌باشید؟",
      icon: faQuestionCircle,
    },
  ];

  const handleStart = () => {
    if (userName.trim() !== "") {
      setCurrentQuestion(0);
      setHistory([]);
      setRiskLevel("");
    }
  };

  const handleResponse = (answer) => {
    const newHistory = [
      ...history,
      { question: questions[currentQuestion], answer },
    ];
    setHistory(newHistory);

    if (currentQuestion === 0 && questions[currentQuestion].isIntro) {
      setCurrentQuestion(currentQuestion + 1); // Move to real questions
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setLoading(true);

      // Filter out the introduction response before sending
      const realResponses = newHistory
        .filter((h) => !h.question.isIntro) // Exclude intro
        .map((h) => h.answer); // Keep only the answers

      axios
        .post(
          `${API}api/user/fall_risk/`,
          { responses: realResponses }, // Send only real responses
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + Cookies.get("token"),
            },
          }
        )
        .then((res) => {
          setRiskLevel(res.data.risk_level);
          setLoading(false);
        })
        .catch(() => {
          setRiskLevel("خطا در دریافت سطح ریسک.");
          setLoading(false);
        });
    }
  };

  // Scroll to the latest message when history updates
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [history, riskLevel, loading]); // Trigger scroll on history, risk level, or loading change

  return (
    <div className="fallrisk-chat-container fallrisk-rtl-text">
      <div className="fallrisk-chat-window">
        <div className="fallrisk-chat-header">
          <h2 className="fallrisk-title">ربات ارزیاب ریسک زمین خوردن</h2>
        </div>
        <div className="fallrisk-chat-body" ref={chatBodyRef}>
          {currentQuestion === -1 && (
            <div className="fallrisk-intro">
              <input
                type="text"
                placeholder="نام خود را وارد کنید"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <button onClick={handleStart} disabled={!userName}>
                شروع ارزیابی
              </button>
            </div>
          )}

          {/* Display chat history with each message on a new line */}
          {history.map((entry, index) => (
            <React.Fragment key={index}>
              {/* Display System Message (Question) with Icon */}
              <div className="fallrisk-message fallrisk-received">
                <p>
                  <FontAwesomeIcon
                    icon={entry.question.icon}
                    style={{ marginLeft: "8px", color: "#00796b" }}
                  />{" "}
                  {entry.question.text}
                </p>
              </div>

              {/* Display User Response (Answer) */}
              <div className="fallrisk-message fallrisk-sent">
                <p>{entry.answer}</p>
              </div>
            </React.Fragment>
          ))}

          {/* Display current question */}
          {!riskLevel &&
            currentQuestion >= 0 &&
            currentQuestion < questions.length && (
              <div className="fallrisk-message fallrisk-received">
                <p>
                  <FontAwesomeIcon
                    icon={questions[currentQuestion].icon}
                    style={{ marginLeft: "8px", color: "#00796b" }}
                  />
                  {typeof questions[currentQuestion].text === "function"
                    ? questions[currentQuestion].text(userName)
                    : questions[currentQuestion].text}
                </p>
                <div className="fallrisk-buttons">
                  {questions[currentQuestion].isIntro ? (
                    <button
                      className="fallrisk-yes-button"
                      onClick={() => handleResponse("باشه")}
                    >
                      باشه
                    </button>
                  ) : (
                    <>
                      <button
                        className="fallrisk-yes-button"
                        onClick={() => handleResponse("بله")}
                      >
                        بله
                      </button>
                      <button
                        className="fallrisk-no-button"
                        onClick={() => handleResponse("خیر")}
                      >
                        خیر
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}

          {loading && (
            <p className="fallrisk-loading">در حال محاسبه سطح ریسک...</p>
          )}
          {riskLevel && (
            <div className="fallrisk-message fallrisk-received">
              <p>
                {userName} عزیز، سطح ریسک شما: {riskLevel}{" "}
                {riskIcons[riskLevel]}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FallRisk;
