import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./TourDetails.css";
import { API } from "../backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faUserTie,
  faPersonWalkingWithCane,
  faStar,
  faClock,
  faCalendarDay,
  faMapMarkerAlt,
  faPlane,
  faUsers,
  faRuler,
  faTruckPlane,
  faHotel,
  faMapPin,
  faLocationDot,
  faPeopleGroup,
  faScaleUnbalanced,
  faSmile,
  faWalking,
  faHiking,
  faMountain,
  faSkull,
  faCouch,
} from "@fortawesome/free-solid-svg-icons";
import jalaali from "jalaali-js"; // Import jalaali-js library
import Footer from "../components/Sections/Footer";

import styled from "styled-components";
import ConfettiReserve from "./ConfettiReserve";
import TourMap from "./TourMap";
import BlogNavbar from "../components/Nav/BlogNavbar";

export default function TourDetails() {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("timeline"); // Default tab
  const [enlargedImage, setEnlargedImage] = useState(null);

  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const response = await axios.get(`${API}api/tour/${id}/`);
        setTour(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching tour details:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchTourDetails();
  }, [id]);

  const [foodOptions, setFoodOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectFood = (food) => {
    setFormData({ ...formData, selectedFood: food });
    setDropdownOpen(false);
  };

  const filteredFoods = foodOptions.filter((food) =>
    food.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    axios
      .get(`${API}api/foods/`)
      .then((response) => setFoodOptions(response.data))
      .catch((error) => console.error("Error fetching food options:", error));
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: "",
    age: "",
    participants: "",
    selectedFood: "",
    needsTransport: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [showConfetti, setShowConfetti] = useState(false); // State to handle confetti display
  const [showOverlay, setShowOverlay] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      // Allow only digits and limit to 11 characters
      const phoneValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      setFormData((prev) => ({
        ...prev,
        [name]: phoneValue.length <= 11 ? phoneValue : prev.phoneNumber,
      }));
    } else if (name === "participants") {
      // Ensure participants is at least 1
      const participantsValue = parseInt(value, 10);
      setFormData((prev) => ({
        ...prev,
        [name]: Math.max(1, isNaN(participantsValue) ? 1 : participantsValue),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${API}api/formreserve/`, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phoneNumber,
        gender: formData.gender,
        age: formData.age,
        participants: formData.participants,
        tour: tour.id,
        selected_food: formData.selectedFood ? formData.selectedFood.id : null,
        needs_transport: formData.needsTransport === "true",
      });
      setSuccessMessage("رزرو شما با موفقیت انجام شد!");
      setShowConfetti(true);
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        gender: "",
        participants: "",
        age: "",
        selectedFood: "",
        needsTransport: "",
      });
    } catch (error) {
      console.error("Error creating reservation:", error);
      alert("ایجاد رزرو با مشکل مواجه شد. لطفا دوباره تلاش کنید.");
    }
  };

  const isFormComplete = Object.values(formData).every((field) => field !== "");

  const handleImageClick = (imageUrl) => {
    setEnlargedImage(imageUrl);
  };

  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };

  if (isLoading)
    return <div className="tour-loading-message">در حال بارگذاری...</div>;
  if (isError)
    return <div className="tour-error-message">خطا در دریافت اطلاعات تور.</div>;

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % tour.images.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prev) => (prev - 1 + tour.images.length) % tour.images.length
    );
  };

  const renderAllTabsContent = () => {
    const tabs = [
      { key: "timeline", title: "زمان‌بندی", content: tour.timeline },
      { key: "tours", title: "گشت‌ها", content: tour.tours },
      { key: "services", title: "خدمات", content: tour.services },
      { key: "accommodation", title: "اقامتگاه", content: tour.accommodation },
      { key: "map", title: "نقشه", content: null }, // Map has no direct content, rendered separately
      { key: "facilities", title: "امکانات", content: tour.facilities },
      { key: "equipment", title: "لوازم", content: tour.equipment },
      { key: "conditions", title: "توضیحات", content: tour.conditions },
      {
        key: "cancellation",
        title: "قوانین کنسلی",
        content: tour.cancellation_rules,
      },
    ];

    return (
      <div className="all-tabs-content">
        {tabs.map(({ key, title, content }) => (
          <div
            className={`tab-section ${activeTab === key ? "active" : ""}`}
            id={key}
            key={key}
          >
            <h3 className="tab-title">{title}</h3>
            <div className="tab-content">
              {key === "map" ? (
                // Render the map component here
                <TourMap
                  source={tour.source}
                  destination={tour.destination}
                  sourceCoords={[tour.source_lat, tour.source_lng]}
                  destinationCoords={[
                    tour.destination_lat,
                    tour.destination_lng,
                  ]}
                />
              ) : content ? (
                Array.isArray(content) || typeof content === "object" ? (
                  Object.entries(content).map(([key, value]) => (
                    <div className="tour-condition-item" key={key}>
                      <div className="icon">✔️</div>
                      <span>{value}</span>
                    </div>
                  ))
                ) : (
                  content
                )
              ) : (
                "اطلاعاتی برای این بخش موجود نیست."
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "timeline":
        return (
          <div className="tour-tab-content">
            {Object.entries(tour.timeline || {}).map(([key, value]) => (
              <div className="tour-condition-item" key={key}>
                {/* {key}. {value} */}
                <div className="icon">✔️</div>
                <span>{value}</span>
              </div>
            ))}
          </div>
        );
      case "tours":
        return (
          <div className="tour-tab-content">
            {Object.entries(tour.tours || {}).map(([key, value]) => (
              <div className="tour-condition-item" key={key}>
                <div className="icon">✔️</div>
                <span>{value}</span>
              </div>
            ))}
          </div>
        );
      case "services":
        return (
          <div className="tour-tab-content">
            {Object.entries(tour.services || {}).map(([key, value]) => (
              <div className="tour-condition-item" key={key}>
                <div className="icon">✔️</div>
                <span>{value}</span>
              </div>
            ))}
          </div>
        );
      case "accommodation":
        return (
          <div className="tour-tab-content">
            {tour.accommodation || "اطلاعاتی برای این بخش موجود نیست."}
          </div>
        );
      case "map":
        return (
          <div className="tour-tab-content">
            {tour.map || "اطلاعاتی برای این بخش موجود نیست."}
          </div>
        );
      case "facilities":
        return (
          <div className="tour-tab-content">
            {Object.entries(tour.facilities || {}).map(([key, value]) => (
              <div className="tour-condition-item" key={key}>
                <div className="icon">✔️</div>
                <span>{value}</span>
              </div>
            ))}
          </div>
        );
      case "equipment":
        return (
          <div className="tour-tab-content">
            {Object.entries(tour.equipment || {}).map(([key, value]) => (
              <div className="tour-condition-item" key={key}>
                <div className="icon">✔️</div>
                <span>{value}</span>
              </div>
            ))}
          </div>
        );
      case "conditions":
        return (
          <div className="tour-tab-content">
            {Object.entries(tour.conditions || {}).map(([key, value]) => (
              <div className="tour-condition-item" key={key}>
                <div className="icon">✔️</div>
                <span>{value}</span>
              </div>
            ))}
          </div>
        );
      default:
        return <div className="tour-tab-content">بخش انتخاب شده یافت نشد.</div>;
    }
  };

  const convertToJalali = (gregorianDate) => {
    const date = new Date(gregorianDate);
    const jalaaliDate = jalaali.toJalaali(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    return `${jalaaliDate.jy}/${jalaaliDate.jm}/${jalaaliDate.jd}`;
  };

  return (
    <div>
      <div className="navbar-div-profile">
        <BlogNavbar />
      </div>
      <div className="tour-details-wrapper">
        {enlargedImage && (
          <div
            className="tour-enlarged-image-modal"
            onClick={closeEnlargedImage}
          >
            <img src={enlargedImage} alt="Enlarged" />
          </div>
        )}
        <div className="tour-content-wrapper">
          <div className="tour-reserve-form">
            <h2>رزرو تور</h2>
            <form className="tour-reserve-form" onSubmit={handleSubmit}>
              <StyledInput
                type="text"
                name="firstName"
                placeholder="نام"
                value={formData.firstName}
                onChange={handleChange}
              />
              <StyledInput
                type="text"
                name="lastName"
                placeholder="نام خانوادگی"
                value={formData.lastName}
                onChange={handleChange}
              />
              <StyledInput
                type="text"
                name="phoneNumber"
                placeholder="شماره تماس"
                maxLength={11}
                minLength={11}
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <StyledSelect
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="">جنسیت</option>
                <option value="male">مرد</option>
                <option value="female">زن</option>
              </StyledSelect>
              <StyledInput
                type="number"
                name="age"
                placeholder="سن"
                value={formData.age}
                onChange={handleChange}
              />
              <StyledInput
                type="number"
                name="participants"
                placeholder="تعداد نفرات"
                value={formData.participants}
                onChange={handleChange}
              />
              <StyledSelect
                name="needsTransport"
                value={formData.needsTransport}
                onChange={handleChange}
              >
                <option value="">آیا به حمل و نقل نیاز دارید؟</option>
                <option value="false">خیر</option>
                <option value="true">بله</option>
              </StyledSelect>
              <div className="custom-dropdown">
                <div
                  className="dropdown-header"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {formData.selectedFood ? (
                    <div className="selected-food">
                      <img
                        src={formData.selectedFood.image}
                        alt={formData.selectedFood.name}
                        className="food-image"
                      />
                      <div className="food-info">
                        <span className="food-name">
                          {formData.selectedFood.name}
                        </span>
                        <span className="food-price">
                          {Number(formData.selectedFood.price).toLocaleString(
                            "fa-IR"
                          )}{" "}
                          ریال
                        </span>
                      </div>
                    </div>
                  ) : (
                    <span className="placeholder">انتخاب غذا</span>
                  )}
                </div>

                {dropdownOpen && (
                  <div className="dropdown-container">
                    <input
                      type="text"
                      className="dropdown-search"
                      placeholder="جستجوی غذا..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <ul className="dropdown-list">
                      {filteredFoods.length > 0 ? (
                        filteredFoods.map((food) => (
                          <li
                            key={food.id}
                            className="dropdown-item"
                            onClick={() => handleSelectFood(food)}
                          >
                            <img
                              src={food.image}
                              alt={food.name}
                              className="food-image"
                            />
                            <div className="food-info">
                              <span className="food-name">{food.name}</span>
                              <span className="food-price">
                                {Number(food.price).toLocaleString("fa-IR")}{" "}
                                ریال
                              </span>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className="no-results">نتیجه‌ای یافت نشد</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <SubmitButton
                type="submit"
                disabled={tour.is_full || !isFormComplete}
              >
                {tour.is_full ? "ظرفیت پر شده است" : "رزرو"}
              </SubmitButton>
            </form>
          </div>

          <div className="tour-details-content">
            <h1>{tour.title}</h1>
            <div className="tour-header-details">
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faUserTie}
                    className="tour-detail-icon"
                  />
                  مجری تور:
                </strong>
                <div className="tour-organizer">
                  {tour.organizer?.image && (
                    <img
                      className="tour-small-image"
                      src={tour.organizer.image}
                      alt={tour.organizer.name}
                      onClick={() => handleImageClick(tour.organizer.image)}
                    />
                  )}
                  {tour.organizer?.name || "اطلاعات موجود نیست"}
                </div>
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faPersonWalkingWithCane}
                    className="tour-detail-icon"
                  />
                  تورلیدر:
                </strong>
                <div className="tour-leader">
                  {tour.leader?.image && (
                    <img
                      className="tour-small-image"
                      src={tour.leader.image}
                      alt={tour.leader.name}
                      onClick={() => handleImageClick(tour.leader.image)}
                    />
                  )}
                  {tour.leader?.name || "اطلاعات موجود نیست"}
                </div>
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon icon={faStar} className="tour-detail-icon" />
                  امتیاز تور:
                </strong>{" "}
                {tour.rating || "4"} از 5
              </div>
            </div>
            <div className="tour-details-table">
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faClock}
                    className="tour-detail-icon"
                  />
                  مدت سفر:
                </strong>{" "}
                {tour.duration || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="tour-detail-icon"
                  />
                  تاریخ رفت:
                </strong>{" "}
                {convertToJalali(tour.start_date) || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="tour-detail-icon"
                  />
                  تاریخ برگشت:
                </strong>{" "}
                {convertToJalali(tour.end_date) || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="tour-detail-icon"
                  />
                  مبدا:
                </strong>{" "}
                {tour.source || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faMapPin}
                    className="tour-detail-icon"
                  />
                  مقصد:
                </strong>{" "}
                {tour.destination || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faHotel}
                    className="tour-detail-icon"
                  />
                  نوع اقامت:
                </strong>{" "}
                {tour.accommodation || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faTruckPlane}
                    className="tour-detail-icon"
                  />
                  حمل و نقل:
                </strong>{" "}
                {tour.transportation || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faPeopleGroup}
                    className="tour-detail-icon"
                  />
                  رده سنی:
                </strong>{" "}
                {tour.age_range || "اطلاعات موجود نیست"}
              </div>
              <div className="tour-detail-row">
                <strong>
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="tour-detail-icon"
                  />
                  ظرفیت گروه:
                </strong>{" "}
                {tour.capacity || "اطلاعات موجود نیست"}
              </div>
              <div
                className="tour-detail-row"
                onMouseEnter={() => setShowOverlay(true)}
                onMouseLeave={() => setShowOverlay(false)}
              >
                <strong>
                  <FontAwesomeIcon
                    icon={faScaleUnbalanced}
                    className="tour-detail-icon"
                  />
                  درجه سختی:
                </strong>
                <span
                  className="difficulty-value"
                  onMouseEnter={() => setShowOverlay(true)}
                  onMouseLeave={() => setShowOverlay(false)}
                >
                  {tour.difficulty || "2 از 5"}
                </span>
                {showOverlay && (
                  <div className="difficulty-overlay">
                    {tour.difficulty === "1 از 5" && (
                      <div className="difficulty-item">
                        <p>
                          آسان (سختی کم){" "}
                          <FontAwesomeIcon
                            icon={faCouch}
                            className="difficulty-icon"
                          />
                          <br />
                          این تورها برای افراد با تحرک کم و سالمندانی که نیاز به
                          مراقبت بیشتری دارند طراحی شده است.
                          <br />
                          مسیرها کاملاً صاف و بدون پله یا موانع هستند.
                          <br />
                          معمولاً شامل فعالیت‌های آرام مانند بازدید از باغ‌ها،
                          موزه‌ها یا مراکز خرید است.
                          <br />
                          همچنین دارای امکانات حمل و نقل راحتی مانند اتوبوس‌های
                          ویژه برای سالمندان است.
                          <br />
                          زمان صرف شده در این تورها معمولاً کوتاه است و
                          استراحت‌های مکرر در نظر گرفته می‌شود.
                        </p>
                      </div>
                    )}
                    {tour.difficulty === "2 از 5" && (
                      <div className="difficulty-item">
                        <p>
                          نسبتاً آسان (سختی ملایم){" "}
                          <FontAwesomeIcon
                            icon={faWalking}
                            className="difficulty-icon"
                          />
                          <br />
                          این تورها برای افرادی که دارای توانایی بدنی متوسط
                          هستند مناسب است.
                          <br />
                          ممکن است شامل پیاده‌روی‌های کوتاه (حداکثر ۱-۲ ساعت) در
                          مسیرهایی با شیب ملایم باشد.
                          <br />
                          فعالیت‌ها می‌توانند شامل بازدید از اماکن تاریخی،
                          فرهنگی یا طبیعی باشند که نیاز به جابجایی بیشتر دارد.
                          <br />
                          خدمات حمل و نقل راحت وجود دارد و معمولاً در بین
                          فعالیت‌ها فرصت‌های استراحت کافی فراهم می‌شود.
                        </p>
                      </div>
                    )}
                    {tour.difficulty === "3 از 5" && (
                      <div className="difficulty-item">
                        <p>
                          متوسط (سختی معقول){" "}
                          <FontAwesomeIcon
                            icon={faPersonWalkingWithCane}
                            className="difficulty-icon"
                          />
                          <br />
                          این تورها برای افرادی که از سطح فعالیت بدنی بالاتری
                          برخوردارند و توانایی انجام پیاده‌روی‌های طولانی‌تر را
                          دارند طراحی شده است.
                          <br />
                          شامل فعالیت‌هایی مانند پیاده‌روی در طبیعت یا بازدید از
                          مکان‌های دورافتاده‌تر است.
                          <br />
                          زمان صرف شده در فعالیت‌ها بیشتر است و قدری از فشار
                          جسمانی را تحمیل می‌کند، اما همچنان قابل مدیریت است.
                          <br />
                          ممکن است در این تورها نیاز به صعودهای ملایم نیز وجود
                          داشته باشد و بنابراین افراد باید از نظر جسمی آماده
                          باشند.
                        </p>
                      </div>
                    )}
                    {tour.difficulty === "4 از 5" && (
                      <div className="difficulty-item">
                        <p>
                          مشکل (سختی زیاد){" "}
                          <FontAwesomeIcon
                            icon={faHiking}
                            className="difficulty-icon"
                          />
                          <br />
                          این تورها برای افراد با توانایی جسمی بالا و تجربه
                          بیشتر در فعالیت‌های بدنی طراحی شده‌اند.
                          <br />
                          شامل پیاده‌روی‌های طولانی‌تر و فعالیت‌هایی در مناطق
                          کوهستانی یا طبیعی با شیب‌های تندتر است.
                          <br />
                          زمان صرف شده در این تورها بیشتر بوده و برنامه‌ها
                          فشرده‌تر هستند.
                          <br />
                          ممکن است برخی از فعالیت‌ها نیاز به تجهیزات خاص مانند
                          کفش‌های کوه‌نوردی یا نگهدارنده‌های تعادلی داشته باشند.
                        </p>
                      </div>
                    )}
                    {tour.difficulty === "5 از 5" && (
                      <div className="difficulty-item">
                        <p>
                          بسیار سخت (سختی بسیار زیاد){" "}
                          <FontAwesomeIcon
                            icon={faMountain}
                            className="difficulty-icon"
                          />
                          <br />
                          این تورها برای افرادی که آمادگی جسمانی عالی دارند و از
                          نظر روحی نیز آماده‌ی چالش‌های جدی هستند مناسب است.
                          <br />
                          معمولاً شامل کوه‌پیمایی‌های طولانی، صعود به ارتفاعات
                          بالا و فعالیت‌های پرتحرک در مناطق دورافتاده هستند.
                          <br />
                          در این تورها امکان دسترسی به خدمات پزشکی محدود است و
                          به همین دلیل شرکت‌کنندگان باید از نظر سلامتی کاملاً
                          مطمئن باشند.
                          <br />
                          زمان برنامه‌ریزی شده برای فعالیت‌ها طولانی‌تر است و
                          ممکن است افراد به مدت چند روز در شرایط چالش‌برانگیز
                          قرار گیرند.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="tour-image-gallery">
            <div className="tour-main-image">
              <img
                src={tour.images[currentImageIndex]?.image}
                alt={`Tour ${currentImageIndex + 1}`}
              />
              <div className="tour-arrow-left" onClick={handlePreviousImage}>
                <FontAwesomeIcon className="fa-m" icon={faChevronLeft} />
              </div>
              <div className="tour-arrow-right" onClick={handleNextImage}>
                <FontAwesomeIcon className="fa-m" icon={faChevronRight} />
              </div>
            </div>
            <div className="tour-thumbnail-gallery">
              {tour.images.map((image, index) => (
                <img
                  className={`tour-thumbnail ${
                    index === currentImageIndex ? "active" : ""
                  }`}
                  key={index}
                  src={image.image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="tour-tabs-wrapper">
          {[
            { key: "timeline", label: "زمان‌بندی" },
            { key: "tours", label: "گشت‌ها" },
            { key: "services", label: "خدمات" },
            { key: "accommodation", label: "اقامتگاه" },
            { key: "map", label: "نقشه" },
            { key: "facilities", label: "امکانات" },
            { key: "equipment", label: "لوازم" },
            { key: "conditions", label: "توضیحات" },
            { key: "cancellation", label: "قوانین کنسلی" },
          ].map(({ key, label }) => (
            <div
              className={`tour-tab ${activeTab === key ? "active" : ""}`}
              onClick={() => {
                setActiveTab(key);
                document
                  .getElementById(key)
                  .scrollIntoView({ behavior: "smooth" });
              }}
              key={key}
            >
              {label}
            </div>
          ))}
        </div>

        {renderAllTabsContent()}

        {showConfetti && (
          <ConfettiReserve
            handleClose={() => setShowConfetti(false)}
            handleShowLogin={() => window.location.reload(false)}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &:-webkit-autofill {
    background-color: #fff !important; /* Match input background */
    -webkit-text-fill-color: black !important; /* Match input text color */
  }
`;

const StyledSelect = styled.select`
  display: block;
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const SubmitButton = styled.button`
  background: ${(props) => (props.disabled ? "#cccccc" : "#7620ff")};
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  margin-top: 10px;
  transition: background 0.3s;

  &:hover {
    background: ${(props) => (props.disabled ? "#cccccc" : "#0056b3")};
  }
`;

const StyledCounterInput = styled.input`
  text-align: center;
  width: 80px;
  height: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0 10px;
  outline: none;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const StyledButton = styled.button`
  padding: 0 15px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f7f7f7;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #eaeaea;
  }

  &:active {
    background: #dcdcdc;
  }
`;

const CounterControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
`;
