import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome
import { faHandshake } from '@fortawesome/free-solid-svg-icons'; // Import Handshake icon

import './Cagmo.css'; // Import CSS for styling

const Cagmo = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    name: '',
    age: '',
    // ... more health-related fields
  });

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
    if (!isPanelOpen) {
      setStep(1);
      setFormData({ phoneNumber: '', name: '', age: '' });
    }
  };

  const handleInputChange = (e) => {
    console.log(`Field ${e.target.name} updated to ${e.target.value}`);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const nextStep = () => setStep(step + 1);

  const prevStep = () => setStep(step - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    togglePanel();
  };

  const renderStepContent = () => {
    switch (step) {
        case 1:
            return (
                <div className="Cagmo-step-content">
                <h2>شماره تماس خود را وارد کنید</h2>
                <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="Cagmo-input-field"
                />
            <div className='Cagmo-button-panel'>
                <button className="Cagmo-btn Cagmo-next-btn" onClick={nextStep} disabled={!formData.phoneNumber}>مرحلۀ بعد</button>
            </div>
                </div>
            );
       case 2:
        return (
          <div className="Cagmo-step-content">
            <h2>اطلاعات پایه</h2>
            <input
              type="text"
              name="name"
              placeholder="نام"
              value={formData.name}
              onChange={handleInputChange}
              className="Cagmo-input-field"
            />
            <input
              type="number"
              name="age"
              placeholder="سن"
              value={formData.age}
              onChange={handleInputChange}
              className="Cagmo-input-field"
            />
            <div className='Cagmo-button-panel'>
                <button className="Cagmo-btn Cagmo-prev-btn" onClick={prevStep}>مرحلۀ قبلی</button>
                <button className="Cagmo-btn Cagmo-next-btn" onClick={nextStep} disabled={!formData.name || !formData.age}>مرحلۀ بعد</button>
            </div>
          </div>
        );
    //   case 3:
    //     return (
    //       <div className="Cagmo-step-content">
    //         <h2>سابقه سلامتی</h2>
    //         {/* Add health-related questions */}
    //         <div className='Cagmo-button-panel'>
    //             <button className="Cagmo-btn Cagmo-prev-btn" onClick={prevStep}>مرحلۀ قبلی</button>
    //             <button className="Cagmo-btn Cagmo-next-btn" onClick={nextStep}>مرحلۀ بعد</button>
    //         </div>
    //       </div>
    //     );
    //   case 4:
    //     return (
    //       <div className="Cagmo-step-content">
    //         <h2>Lifestyle</h2>
    //         {/* Add lifestyle-related questions */}
    //         <button className="Cagmo-btn Cagmo-prev-btn" onClick={prevStep}>Previous</button>
    //         <button className="Cagmo-btn Cagmo-submit-btn" type="submit" onClick={handleSubmit}>Submit</button>
    //       </div>
    //     );
      default:
        return null;
    }
  };

  return (
    <div className="Cagmo-health-profile-container">
      {!isPanelOpen && (
        <div className="Cagmo-pre-panel">
          <div className="Cagmo-logos">
            <img src={require("../images/Logo_Salamatmand_Final2.png")} alt="Logo 1" className="Cagmo-logo" />
            <FontAwesomeIcon icon={faHandshake} className="Cagmo-cooperation-icon" />
            <img src={require("../images/cogmo logo final_send.png")} alt="Logo 2" className="Cagmo-logo" />
          </div>
          <button className="Cagmo-toggle-btn" onClick={togglePanel}>
            ساخت پروفایل کاگمو-جیم
          </button>
        </div>
      )}
  
      {isPanelOpen && (
        <div className="Cagmo-health-profile-panel">
          <button className="Cagmo-close-panel" onClick={togglePanel}>
            &times;
          </button>
          <form onSubmit={handleSubmit} className="Cagmo-form-container">
            {renderStepContent()}
          </form>
        </div>
      )}
    </div>
  );
  
};

export default Cagmo;
