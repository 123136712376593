import React, { useState, useEffect, useRef } from "react";

import "./Signup.css";

import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPersonCane } from "@fortawesome/free-solid-svg-icons";
import { signup } from "../apis/api";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import i18n from "../i18n"; // Make sure you import the i18n instance
import Signin from "./Signin";
import Confetti from "./Confetti";

const Signup = ({ handleClose }) => {
  const [mobile, setMobile] = useState("");
  const [mobileSalamatyar, setMobileSalamatyar] = useState(""); // For Salmand's Salamatyar mobile
  const [mobileSalmand, setMobileSalmand] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [isNumberExists, setIsNumberExists] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [otherErrors, setOtherErrors] = useState(false);
  const [formType, setFormType] = useState("individual-salamatyar");
  const [showExplanation, setShowExplanation] = useState(true);
  const [showDone, setShowDone] = useState(false);
  const directionClass = i18n.language === "en" ? "ltr-i18n" : "";
  const [mobileError, setMobileError] = useState(false);
  const [salamatyarMobileError, setSalamatyarMobileError] = useState(false);
  const [salmandMobileError, setSalmandMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  // const mobilePattern = /^[0-9]{11}$/;  // Simple pattern for 11-digit mobile numbers
  const mobilePattern = /^([0-9\u06F0-\u06F9]{11})$/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const [showSignin, setShowSignin] = useState(false);

  const [isExploding, setIsExploding] = useState(true);

  const validateFields = () => {
    setMobileError(!mobilePattern.test(mobile));
    setSalamatyarMobileError(
      formType === "individual-salmand" && !mobilePattern.test(mobileSalamatyar)
    );
    setSalmandMobileError(
      formType === "individual-salamatyar" && !mobilePattern.test(mobileSalmand)
    );
    setEmailError(!emailPattern.test(email));

    // Ensure all inputs are valid before proceeding
    return (
      mobilePattern.test(mobile) &&
      (formType !== "individual-salmand" ||
        mobilePattern.test(mobileSalamatyar)) &&
      (formType !== "individual-salamatyar" ||
        mobilePattern.test(mobileSalmand)) &&
      emailPattern.test(email)
    );
  };

  const handleShowDone = () => {
    setShowDone(true);
  };

  const handleShowSignin = () => {
    setIsSignupSuccessful(false);
    setShowSignin(true);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return; // Stop submission if fields are invalid
    }

    const requestData = {
      mobile,
      first_name,
      last_name,
      email,
    };

    if (formType === "individual-salmand") {
      requestData.salamatyar_mobile = mobileSalamatyar; // Salmand registering, include Salamatyar mobile
    } else if (formType === "individual-salamatyar") {
      requestData.salmand_mobile = mobileSalmand; // Salamatyar registering, include Salmand mobile
    }

    signup(requestData)
      .then((data) => {
        if (data.message === "OTP was sent") {
          setIsSignupSuccessful(true);
          handleShowDone(true);
          setIsNumberExists(false);
          setIsEmailExists(false);
          setOtherErrors(false);
        } else if (data.message === "user with this mobile already exists.") {
          setIsNumberExists(true);
          setIsEmailExists(false);
          setOtherErrors(false);
        } else if (data.message === "user with this email already exists.") {
          setIsEmailExists(true);
          setIsNumberExists(false);
          setOtherErrors(false);
        } else {
          setOtherErrors(true);
          setIsEmailExists(false);
          setIsNumberExists(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleHideSignin = () => {
    setShowDone(false);
    handleClose();
  };

  const handleHideConfetti = () => {
    setIsSignupSuccessful(false);
    handleClose();
  };

  const handleCloseExplanation = () => {
    setShowExplanation(false);
  };

  return (
    <>
      {showExplanation && (
        <div className="modal-explanation display-block">
          <section className="modal-main-explanation">
            <div className="xmark-font-awsome-right">
              <button className="submit-filter-but">
                <FontAwesomeIcon
                  onClick={handleClose}
                  className="fontawesome-icon fa-2xl"
                  icon={faXmark}
                  color="#DE3163"
                />
              </button>
            </div>
            <div className="explanation-header">
              <h3>انواع حساب کاربری</h3>
            </div>
            <div className="explanation-content">
              <h4>سلامتمند</h4>
              <p>
                این حساب مخصوص عزیزان سالمند است. در زمان ثبت‌نام، لطفاً شماره
                موبایل خود و شماره موبایل فرزندتان (سلامتیار) را وارد کنید تا
                بتوانیم بهتر در کنار شما باشیم.
              </p>
              <button
                className="submit-changes-form"
                onClick={() => {
                  setFormType("individual-salmand");
                  handleCloseExplanation();
                }}
              >
                ثبت نام سلامتمند
              </button>
              <h4>سلامتیار</h4>
              <p>
                این حساب برای فرزندان مهربانی است که مراقبت از والدین سالمند خود
                را بر عهده دارند. شما می‌توانید هم برای خود و هم برای والدین
                گرامیتان حساب کاربری بسازید. لطفاً شماره موبایل خود و شماره
                موبایل والدینتان را وارد کنید.
              </p>
              <button
                className="submit-changes-form"
                onClick={() => {
                  setFormType("individual-salamatyar");
                  handleCloseExplanation();
                }}
              >
                ثبت نام سلامتیار
              </button>
              <h4>کسب و کارها</h4>
              <p>
                این حساب برای کسب و کارهایی طراحی شده که قصد دارند خدمات خود را
                با عشق و احترام به سالمندان و سلامتیاران ارائه کنند. اگر کسب و
                کاری هستید که دوست دارید با ما در این مسیر همراه شوید، این گزینه
                برای شماست.
              </p>
              <button
                className="submit-changes-form"
                onClick={() => {
                  setFormType("business");
                  handleCloseExplanation();
                }}
              >
                ثبت نام کسب و کارها
              </button>
              {/* <button onClick={handleCloseExplanation} className="submit-changes">رفتن به فرم ثبت‌نام</button> */}
            </div>
          </section>
        </div>
      )}
      {!showExplanation && !isSignupSuccessful && (
        <div className={`modal-add-event display-block`}>
          <section className="modal-main-add-event">
            <div className="xmark-font-awsome-right">
              <button className="submit-filter-but">
                <FontAwesomeIcon
                  onClick={handleClose}
                  className="fontawesome-icon fa-2xl"
                  icon={faXmark}
                  color="#DE3163"
                />
              </button>
            </div>
            {!showSignin && (
              <div className="form-switch">
                <button
                  className={`form-switch-button ${
                    formType === "individual-salamatyar" ? "active" : "disable"
                  }`}
                  onClick={() => setFormType("individual-salamatyar")}
                >
                  سلامتیار
                </button>
                <button
                  className={`form-switch-button ${
                    formType === "individual-salmand" ? "active" : "disable"
                  }`}
                  onClick={() => setFormType("individual-salmand")}
                >
                  سلامتمند
                </button>
                <button
                  className={`form-switch-button ${
                    formType === "business" ? "active" : "disable"
                  }`}
                  onClick={() => setFormType("business")}
                >
                  کسب و کارها
                </button>
              </div>
            )}
            {!showSignin && formType != "business" && (
              <div className="items-list-div">
                <ul className="items-list-ul">
                  <li>
                    <label className="select-label-figma">
                      نام<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                      className="filter-elements1"
                      type="text"
                    />
                  </li>
                  <li>
                    <label className="select-label-figma">
                      نام خانوادگی<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                      className="filter-elements1"
                      type="text"
                    />
                  </li>
                  <li>
                    <label className="select-label-figma">
                      شماره موبایل<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      maxLength={11}
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="filter-elements1"
                      type="text"
                    />
                    {mobileError && (
                      <p className="error">شماره موبایل معتبر نیست.</p>
                    )}
                  </li>

                  {/* Conditional input based on formType */}
                  {formType === "individual-salmand" && (
                    <li>
                      <label className="select-label-figma">
                        شماره موبایل سلامتیار<span className="required">*</span>
                      </label>
                      <br />
                      <input
                        maxLength={11}
                        value={mobileSalamatyar}
                        onChange={(e) => setMobileSalamatyar(e.target.value)}
                        className="filter-elements1"
                        type="text"
                      />
                      {salamatyarMobileError && (
                        <p className="error">
                          شماره موبایل سلامتیار معتبر نیست.
                        </p>
                      )}
                    </li>
                  )}

                  {formType === "individual-salamatyar" && (
                    <li>
                      <label className="select-label-figma">
                        شماره موبایل سالمند<span className="required">*</span>
                      </label>
                      <br />
                      <input
                        maxLength={11}
                        value={mobileSalmand}
                        onChange={(e) => setMobileSalmand(e.target.value)}
                        className="filter-elements1"
                        type="text"
                      />
                      {salmandMobileError && (
                        <p className="error">شماره موبایل سالمند معتبر نیست.</p>
                      )}
                    </li>
                  )}

                  <li>
                    <label className="select-label-figma">
                      ایمیل<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="filter-elements1"
                      type="text"
                    />
                    {emailError && <p className="error">ایمیل معتبر نیست.</p>}
                  </li>
                </ul>

                <div className="relative-blinker">
                  {isNumberExists && (
                    <div className="no-number">
                      <p className="no-number1">شماره وارد شده ثبت شده است.</p>
                    </div>
                  )}

                  {isEmailExists && (
                    <div className="no-number">
                      <p className="no-number1">ایمیل وارد شده ثبت شده است.</p>
                    </div>
                  )}

                  {otherErrors && (
                    <div className="no-number">
                      <p className="no-number1">
                        داده های ورودی را بررسی کنید.
                      </p>
                    </div>
                  )}
                </div>

                <div>
                  {formType === "individual-salmand" && (
                    <button
                      disabled={
                        !first_name ||
                        !last_name ||
                        !mobile ||
                        !email ||
                        !mobileSalamatyar
                      }
                      className={
                        first_name &&
                        last_name &&
                        mobile &&
                        email &&
                        mobileSalamatyar
                          ? "submit-changes"
                          : "submit-changes-disabled"
                      }
                      onClick={onSubmit}
                    >
                      تایید
                    </button>
                  )}

                  {formType === "individual-salamatyar" && (
                    <button
                      disabled={
                        !first_name ||
                        !last_name ||
                        !mobile ||
                        !email ||
                        !mobileSalmand
                      }
                      className={
                        first_name &&
                        last_name &&
                        mobile &&
                        email &&
                        mobileSalmand
                          ? "submit-changes"
                          : "submit-changes-disabled"
                      }
                      onClick={onSubmit}
                    >
                      تایید
                    </button>
                  )}
                </div>
              </div>
            )}

            {!showSignin && formType == "business" && (
              <div className="items-list-div">
                <ul className="items-list-ul">
                  <li>
                    <label className="select-label-figma">
                      نام شرکت<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      disabled={true}
                      className="filter-elements1"
                      type="text"
                    />
                  </li>
                  <li>
                    <label className="select-label-figma">
                      شماره تماس<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      disabled={true}
                      className="filter-elements1"
                      type="text"
                    />
                  </li>
                  <li>
                    <label className="select-label-figma">
                      ایمیل کاری<span className="required">*</span>
                    </label>
                    <br />
                    <input
                      disabled={true}
                      className="filter-elements1"
                      type="text"
                    />
                  </li>
                </ul>
                <button className={"submit-changes-disabled"}>
                  ثبت درخواست همکاری
                </button>
              </div>
            )}
          </section>
        </div>
      )}
      {/* {showDone && <Signin handleClose={handleHideSignin}/>} */}
      {isSignupSuccessful && (
        <Confetti
          handleClose={handleHideConfetti}
          handleShowLogin={handleShowSignin}
        />
      )}

      {showSignin && <Signin handleClose={handleHideSignin} />}
    </>
  );
};

export default Signup;
