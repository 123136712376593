import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { FaUserCircle } from "react-icons/fa";
import "./Profile.css";
import axios from "axios";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { getUserInfo } from "../apis/api";
import { API } from "../backend";
import { Navbar } from "../pages/home/components/Navbar";
import BlogNavbar from "../components/Nav/BlogNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp91,
  faBalanceScale,
  faCalendarAlt,
  faCapsules,
  faComment,
  faImage,
  faListNumeric,
  faPenToSquare,
  faPills,
  faPlus,
  faPrescriptionBottleAlt,
  faStopwatch,
  faSyringe,
  faTablet,
  faTablets,
  faTrashAlt,
  faXmark,
  faUser,
  faUserNurse,
  faUserMd,
} from "@fortawesome/free-solid-svg-icons";
import { AiOutlineFileText } from "react-icons/ai";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import jalaali from "jalaali-js";
import DeleteConfirmation from "./DeleteConfirmation";
import EditReminderForm from "./EditReminderForm";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

const Profile = ({ children }) => {
  const [file, setFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [data, setData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [chestAnomalyFile, setChestAnomalyFile] = useState(null);
  const [chestAnomalyPdfUrl, setChestAnomalyPdfUrl] = useState("");
  const [chestAnomalyUploadProgress, setChestAnomalyUploadProgress] =
    useState(0);
  const [isChestAnomalyLoading, setIsChestAnomalyLoading] = useState(false);

  const dosageUnitTranslations = {
    tablet: "قرص",
    capsule: "کپسول",
    "gravimetric/mg": "میلی‌گرم (mg)",
    "gravimetric/iu": "واحد بین‌المللی (iu)",
    "volumetric/ml": "میلی‌لیتر (ml)",
  };

  const routeOfAdministrationTranslations = {
    oral: "خوراکی",
    "parentral/im": "تزریق عضلانی",
    "parentral/sc": "تزریق زیرجلدی",
    "parentral/iv": "تزریق وریدی",
  };

  const dosageFormTranslations = {
    tablet: "قرص",
    capsule: "کپسول",
    syrup: "شربت",
    injectable: "تزریقی",
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!Cookies.get("token")) {
      navigate("/");
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  // Handlers for Blood Test File Drop
  const onBloodTestDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setFile(file);
      // You can preview the file if needed
    }
  }, []);

  const onChestAnomalyDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setChestAnomalyFile(file);
      // You can preview the file if needed
    }
  }, []);

  const bloodTestDropzone = useDropzone({
    onDrop: onBloodTestDrop,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const chestAnomalyDropzone = useDropzone({
    onDrop: onChestAnomalyDrop,
    accept: "image/*",
    // accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const [userInfo, setUserInfo] = useState([]);
  const [userInfoLoaded, setUserInfoLoaded] = useState([]);
  const [error, setError] = useState([]);

  const loadUserInfo = () => {
    getUserInfo().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setUserInfo(data);
        setUserInfoLoaded(true);
      }
    });
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const [paymentUrl, setPaymentUrl] = useState(null);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const handlePayment = async (testType) => {
    try {
      setIsLoadingPayment(true);
      const response = await fetch(`${API}api/user/initiate_payment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + Cookies.get("token"),
        },
        body: JSON.stringify({
          test_type: testType,
          mobile: userInfo.mobile,
          email: userInfo.email,
        }),
      });

      const result = await response.json();

      if (result.authority) {
        setPaymentUrl(result.payment_url);
      } else {
        alert("Failed to initiate payment.");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const [remainingTests, setRemainingTests] = useState(null);
  const [capacityTests, setCapacityTests] = useState(null);
  const [canTakeTest, setCanTakeTest] = useState(null);
  const [lastTestDate, setLastTestDate] = useState(null);

  const [remainingTestsAnomaly, setRemainingTestsAnomaly] = useState(null);
  const [anomalyCapacityTests, setAnomalyCapacityTests] = useState(null);
  const [canTakeTestAnomaly, setCanTakeTestAnomaly] = useState(null);
  const [lastTestDateAnomaly, setLastTestDateAnomaly] = useState(null);

  const [remainingMedication, setRemainingMedication] = useState(null);
  const [medicationCapacity, setMedicationCapacity] = useState(null);
  const [canTakeMedication, setCanTakeMedication] = useState(null);

  useEffect(() => {
    const fetchRemainingTests = async () => {
      try {
        // Get token from localStorage

        const response = await fetch(`${API}api/user/test_usage/`, {
          method: "GET",
          headers: {
            Authorization: "Token " + Cookies.get("token"),
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRemainingTests(data.remaining_tests);
          setCapacityTests(data.test_capacity);
          setCanTakeTest(data.can_take_test);
          setLastTestDate(data.last_test_date);
        } else {
          console.error("Error fetching test usage:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching test usage:", error);
      }
    };

    fetchRemainingTests();
  }, []);

  const formattedLastTestDate = lastTestDate
    ? new Date(lastTestDate).toLocaleDateString("fa-IR") // Format date as Persian
    : "هیچ آزمایشی انجام نشده است";

  useEffect(() => {
    const fetchRemainingTestsAnomaly = async () => {
      try {
        // Get token from localStorage

        const response = await fetch(`${API}api/user/anomaly_test_usage/`, {
          method: "GET",
          headers: {
            Authorization: "Token " + Cookies.get("token"),
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRemainingTestsAnomaly(data.remaining_anomaly_tests);
          setAnomalyCapacityTests(data.anomaly_test_capacity);
          setCanTakeTestAnomaly(data.can_take_anomaly_test);
          setLastTestDateAnomaly(data.last_anomaly_test_date);
        } else {
          console.error("Error fetching test usage:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching test usage:", error);
      }
    };

    fetchRemainingTestsAnomaly();
  }, []);

  const formattedLastTestDateAnomaly = lastTestDateAnomaly
    ? new Date(lastTestDateAnomaly).toLocaleDateString("fa-IR") // Format date as Persian
    : "هیچ آزمایشی انجام نشده است";

  useEffect(() => {
    const fetchRemainingCapacity = async () => {
      try {
        // Get token from localStorage

        const response = await fetch(`${API}api/user/medication_usage/`, {
          method: "GET",
          headers: {
            Authorization: "Token " + Cookies.get("token"),
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRemainingMedication(data.remaining_medication);
          setMedicationCapacity(data.medication_capacity);
          setCanTakeMedication(data.can_add_medication);
        } else {
          console.error("Error fetching test usage:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching test usage:", error);
      }
    };

    fetchRemainingCapacity();
  }, []);

  const handleSubmit = async () => {
    if (!file) {
      alert("Please upload a file.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${API}api/user/upload_blood_test/`, {
        method: "POST",
        headers: {
          Authorization: "Token " + Cookies.get("token"),
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        localStorage.setItem("testResult", JSON.stringify(result));
        navigate("/test-result");
      } else {
        alert("Error uploading file");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading file");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitChestXray = async () => {
    if (!chestAnomalyFile) {
      alert("Please upload a chest X-ray image.");
      return;
    }

    setIsChestAnomalyLoading(true);

    const formData = new FormData();
    formData.append("file", chestAnomalyFile);

    try {
      const response = await fetch(`${API}api/user/upload_chest_xray/`, {
        method: "POST",
        headers: {
          Authorization: "Token " + Cookies.get("token"),
        },
        body: formData,
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        setChestAnomalyPdfUrl(url);
      } else {
        alert("Error uploading chest X-ray.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading chest X-ray.");
    } finally {
      setIsChestAnomalyLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState("self");

  const [stepReminder, setStepReminder] = useState(1); // Tracks the current step (1 = select recipient, 2 = form)

  const handleRecipientSelection = (recipient) => {
    setSelectedRecipient(recipient); // Save selected recipient
    setStepReminder(2); // Move to the form step
  };

  const [formData, setFormData] = useState({
    medicine_name: "",
    route_of_administration: "",
    dosage_form: "",
    dosage_unit_of_measure: "",
    periodic_interval: "",
    dosage_frequency: "",
    dosage_quantity_of_units_per_time: "",
    first_time_of_intake: null,
    is_chronic_or_acute: "false",
    stopped_by_datetime: null,
    intake_time: null,
    stopped_time: null,
    regimen_note: "",
    recipient: "self",
  });

  const [selectedDay, setSelectedDay] = useState(null);

  const [errors, setErrors] = useState({});

  const fetchReminders = async () => {
    try {
      const response = await axios.get(`${API}api/medication/`, {
        headers: {
          Authorization: "Token " + Cookies.get("token"),
        },
      });
      setReminders(response.data);
    } catch (error) {
      console.error("Error fetching reminders:", error);
    }
  };

  // const validateForm = () => {
  //   const newErrors = {};
  //   if (formData.medicine_name.length < 5) {
  //     newErrors.medicine_name = "Medication name is required and should be at least 5 characters long.";
  //   }
  //   if (!formData.route_of_administration) {
  //     newErrors.route_of_administration = "A route must be selected.";
  //   }
  //   if (!formData.dosage_form) {
  //     newErrors.dosage_form = "A form must be selected.";
  //   }
  //   if (!formData.dosage_unit_of_measure) {
  //     newErrors.dosage_unit_of_measure = "A unit must be selected.";
  //   }
  //   if (!formData.periodic_interval) {
  //     newErrors.periodic_interval = "A periodic interval must be provided.";
  //   }
  //   if (!formData.dosage_frequency || isNaN(formData.dosage_frequency) || formData.dosage_frequency <= 0) {
  //     newErrors.dosage_frequency = "Dosage frequency is required and must be a positive number.";
  //   }
  //   if (!formData.dosage_quantity_of_units_per_time || isNaN(formData.dosage_quantity_of_units_per_time) || formData.dosage_quantity_of_units_per_time <= 0) {
  //     newErrors.dosage_quantity_of_units_per_time = "Quantity of dosage units per time is required and must be positive.";
  //   }
  //   if (!formData.first_time_of_intake) {
  //     newErrors.first_time_of_intake = "A valid date and time must be entered!";
  //   }
  //   if (!formData.is_chronic_or_acute) {
  //     newErrors.is_chronic_or_acute = "Please specify if the condition is chronic or acute.";
  //   }
  //   if (formData.is_chronic_or_acute === 'false' && !formData.stopped_by_datetime) {
  //     newErrors.stopped_by_datetime = "If not chronic, a stop date must be provided.";
  //   }
  //   if (!formData.regimen_note) {
  //     newErrors.regimen_note = "Regimen note is required.";
  //   }

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0; // Return true if no errors
  // };

  const validateForm = () => {
    const newErrors = {};
    if (formData.medicine_name.length < 5) {
      newErrors.medicine_name =
        "نام دارو ضروری است و باید حداقل ۵ کاراکتر باشد.";
    }
    if (!formData.route_of_administration) {
      newErrors.route_of_administration = "یک روش مصرف باید انتخاب شود.";
    }
    if (!formData.dosage_form) {
      newErrors.dosage_form = "یک فرم مصرف باید انتخاب شود.";
    }
    if (!formData.dosage_unit_of_measure) {
      newErrors.dosage_unit_of_measure = "یک واحد باید انتخاب شود.";
    }
    if (!formData.periodic_interval) {
      newErrors.periodic_interval = "یک بازه زمانی باید وارد شود.";
    }
    if (
      !formData.dosage_frequency ||
      isNaN(formData.dosage_frequency) ||
      formData.dosage_frequency <= 0
    ) {
      newErrors.dosage_frequency =
        "تعداد دفعات مصرف ضروری است و باید یک عدد مثبت باشد.";
    }
    if (
      !formData.dosage_quantity_of_units_per_time ||
      isNaN(formData.dosage_quantity_of_units_per_time) ||
      formData.dosage_quantity_of_units_per_time <= 0
    ) {
      newErrors.dosage_quantity_of_units_per_time =
        "مقدار واحدهای مصرف در هر بار ضروری است و باید مثبت باشد.";
    }
    if (!formData.first_time_of_intake) {
      newErrors.first_time_of_intake = "تاریخ و زمان معتبر باید وارد شود!";
    }
    if (!formData.is_chronic_or_acute) {
      newErrors.is_chronic_or_acute =
        "لطفاً مشخص کنید که این وضعیت مزمن است یا حاد.";
    }
    if (
      formData.is_chronic_or_acute === "false" &&
      !formData.stopped_by_datetime
    ) {
      newErrors.stopped_by_datetime =
        "اگر بلند مدت نیست، باید یک تاریخ توقف وارد شود.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const [selectedReminder, setSelectedReminder] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = (reminder) => {
    setSelectedReminder(reminder);
    setIsEditModalOpen(true);
  };

  const handleUpdate = (updatedReminder) => {
    setReminders(
      reminders.map((reminder) =>
        reminder.id === updatedReminder.id ? updatedReminder : reminder
      )
    );
  };

  const handleClose = () => {
    setIsEditModalOpen(false);
    setSelectedReminder(null);
  };

  const formatJalaliDate = (dateObj) => {
    if (!dateObj || !dateObj.year || !dateObj.month || !dateObj.day) return "";
    return `${dateObj.hour}:${dateObj.minute} - ${dateObj.year}/${dateObj.month}/${dateObj.day}`;
  };

  const convertJalaliToGregorian = ({ year, month, day }) => {
    const { gy, gm, gd } = jalaali.toGregorian(year, month, day);
    // Return the date in ISO 8601 format (YYYY-MM-DD)
    return `${gy}-${String(gm).padStart(2, "0")}-${String(gd).padStart(
      2,
      "0"
    )}T00:00:00`;
  };

  const convertJalaliToGregorianWithTime = ({ year, month, day }, time) => {
    if (!time) return `${year}-${month}-${day}T00:00:00`; // Fallback if no time is provided

    const hours = time.hour();
    const minutes = time.minute();
    const { gy, gm, gd } = jalaali.toGregorian(year, month, day);
    return `${gy}-${String(gm).padStart(2, "0")}-${String(gd).padStart(
      2,
      "0"
    )}T${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:00`;
  };

  const addReminder = async (event) => {
    event.preventDefault();
    if (!validateForm()) return; // Validate form

    // const convertedFirstTime = formData.first_time_of_intake
    //   ? convertJalaliToGregorian(formData.first_time_of_intake)
    //   : null;
    // const convertedStoppedDate = formData.stopped_by_datetime
    //   ? convertJalaliToGregorian(formData.stopped_by_datetime)
    //   : null;

    const convertedFirstTime =
      formData.first_time_of_intake && formData.intake_time
        ? convertJalaliToGregorianWithTime(
            formData.first_time_of_intake,
            formData.intake_time
          )
        : null;
    const convertedStoppedDate =
      formData.stopped_by_datetime && formData.stopped_time
        ? convertJalaliToGregorianWithTime(
            formData.stopped_by_datetime,
            formData.stopped_time
          )
        : null;

    // Prepare the new reminder object with converted dates
    const newReminder = {
      ...formData,
      id: Date.now(),
      first_time_of_intake: convertedFirstTime,
      stopped_by_datetime: convertedStoppedDate,
      recipient: selectedRecipient,
    };

    try {
      // Call your API to save the reminder
      await axios.post(`${API}api/medication/`, newReminder, {
        headers: {
          Authorization: "Token " + Cookies.get("token"),
        },
      });
      setReminders([...reminders, newReminder]);
      resetForm();
      window.location.reload(false);
    } catch (error) {
      console.error("Error adding reminder:", error);
    }

    setIsModalOpen(false); // Close modal after adding reminder
  };

  const resetForm = () => {
    setFormData({
      medicine_name: "",
      route_of_administration: "",
      dosage_form: "",
      dosage_unit_of_measure: "",
      periodic_interval: "",
      dosage_frequency: "",
      dosage_quantity_of_units_per_time: "",
      first_time_of_intake: "",
      is_chronic_or_acute: "false",
      stopped_by_datetime: "",
      intake_time: null,
      stopped_time: null,
      regimen_note: "",
    });
    setErrors({});
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteRequest = (itemId) => {
    setItemToDelete(itemId);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      // Call your delete function here
      deleteItem(itemToDelete);
      setShowDeleteConfirmation(false);
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const deleteItem = async (itemId) => {
    // Your delete function (similar to what you wrote earlier)
    try {
      const response = await fetch(`${API}api/medication/${itemId}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + Cookies.get("token"),
        },
      });

      if (response.status === 204) {
        setReminders(reminders.filter((reminder) => reminder.id !== itemId));
      } else {
        console.error("Failed to delete the item.");
      }
    } catch (error) {
      console.error("Error deleting the item:", error);
    }
  };

  useEffect(() => {
    fetchReminders();
  }, []);

  const convertJalaliToGregorianYadet = (jalaliDate) => {
    if (!jalaliDate) return null;

    const { year, month, day, hour, minute } = jalaliDate;
    const gregorian = jalaali.toGregorian(year, month, day);
    return new Date(gregorian.gy, gregorian.gm - 1, gregorian.gd, hour, minute);
  };

  const calculateTimeLeft = (nextDoseTime, stopTime) => {
    if (!nextDoseTime) return null;

    const now = new Date();

    // Check if stop time has passed
    let stopDate = null;
    if (stopTime) {
      stopDate = convertJalaliToGregorianYadet(stopTime);

      if (now > stopDate) {
        return "تاریخ توقف گذشته!"; // Show "تموم" if stop time has passed
      }
    }

    const nextDose = new Date(
      nextDoseTime.year,
      nextDoseTime.month - 1, // JavaScript months are 0-based
      nextDoseTime.day,
      nextDoseTime.hour,
      nextDoseTime.minute
    );

    const difference = nextDose - now;
    if (difference <= 0) {
      return "وقتشه!";
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = reminders.reduce((acc, reminder) => {
        acc[reminder.id] = calculateTimeLeft(
          reminder.next_dose_time,
          reminder.stopped_by_datetime
        );
        return acc;
      }, {});
      setTimeLeft(newTimeLeft);
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup the timer
  }, [reminders]);

  const [isModalChestOpen, setIsModalChestOpen] = useState(false);

  const openChestModal = () => {
    setIsModalChestOpen(true);
  };

  const closeChestModal = () => {
    setIsModalChestOpen(false);
  };

  return (
    <div className="all-profile-div">
      <div className="profile-container">
        <div className="box">
          <h2 className="box-title">پروفایل کاربری</h2>
          <Menu />
        </div>
        <div className="box">
          <h2 className="box-title">اطلاعات کاربر</h2>
          <ProfileForm />
        </div>
        <div className="box">
          <h2 className="box-title">تحلیل هوشمند آزمایش خون</h2>
          <div className="upload-box">
            {/* <input type="file" onChange={handleFileUpload} /> */}
            {!file && (
              <div
                {...bloodTestDropzone.getRootProps({ className: "dropzone" })}
              >
                <input {...bloodTestDropzone.getInputProps()} />
                {bloodTestDropzone.isDragActive ? (
                  <p className="drag-drop-file-text">در حال کشیدن...</p>
                ) : (
                  <p className="manual-upload">{/* انتخاب دستی کارنامه */}</p>
                )}
                <button className="upload-button-home">
                  انتخاب دستی{" "}
                  <AiOutlineFileText
                    className="react-icons-upload"
                    color="#333"
                    title="آپلود فایل"
                  />
                </button>
                <p className="drag-drop-file-text">
                  یا می توانید فایل را در این محل رها نمایید
                </p>
                {uploadProgress > 0 && (
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                )}
              </div>
            )}
            {file && !pdfUrl && (
              <div className="dropzone">
                <p className="drag-drop-file-text-smaller-center">آپلود شد!</p>
              </div>
            )}
            {!pdfUrl && canTakeTest && (
              <button className="edit-button" onClick={handleSubmit}>
                تحلیل کن!
              </button>
            )}

            {/* {!pdfUrl && !canTakeTest && <button className="edit-button disabled" >
                          تحلیل کن!
                        </button>} */}

            {remainingTests !== null && (
              <div className="test-info">
                <p className="test-count">
                  تعداد آزمایش‌های باقی‌مانده:{" "}
                  <span className="test-count-number">{remainingTests}</span> از{" "}
                  <span className="test-total-number">{capacityTests}</span>
                </p>
              </div>
            )}
            {remainingTests != 0 && lastTestDate && (
              <div className="test-info">
                <p className="last-test-date">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  آخرین آزمایش:{" "}
                  <span className="test-date">{formattedLastTestDate}</span>
                </p>
              </div>
            )}
            {remainingTests === 0 ? (
              <div className="test-message test-full">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                <p>
                  شما دیگر نمی‌توانید آزمایشی انجام دهید. ظرفیت شما تمام شده
                  است.
                </p>
              </div>
            ) : (
              canTakeTest !== null && (
                <div
                  className={`test-message ${
                    canTakeTest ? "test-available" : "test-unavailable"
                  }`}
                >
                  {canTakeTest ? (
                    <p>
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                      شما می‌توانید آزمایش جدیدی انجام دهید.
                    </p>
                  ) : (
                    <p>
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                      شما نمی‌توانید در حال حاضر آزمایشی انجام دهید. لطفاً فردا
                      دوباره امتحان کنید.
                    </p>
                  )}
                </div>
              )
            )}

            {isLoading && (
              <div>
                <p>لطفاً منتظر نتیجه باشید...</p>
              </div>
            )}
            {pdfUrl && (
              <div>
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                  دانلود نتیجه
                </a>
              </div>
            )}

            {canTakeTest != null && !canTakeTest && (
              <div>
                {!paymentUrl && (
                  <button
                    className={`payment-button ${
                      isLoadingPayment ? "loading" : ""
                    }`}
                    onClick={() => handlePayment("blood")}
                    disabled={isLoadingPayment}
                  >
                    {isLoadingPayment ? (
                      <span className="spinner"></span>
                    ) : (
                      "افزایش ظرفیت - 35 هزار تومان"
                    )}
                  </button>
                )}

                {paymentUrl && (
                  <button
                    className="payment-button"
                    onClick={() => (window.location.href = paymentUrl)}
                  >
                    تکمیل پرداخت
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="box">
          <h2 className="box-title">
            تحلیل هوشمند ناهنجاری&#8204;های قفسه سینه
          </h2>
          <div className="upload-box">
            {/* <input type="file" onChange={handleFileUpload} /> */}
            {!chestAnomalyFile &&
              !isChestAnomalyLoading &&
              !chestAnomalyPdfUrl && (
                <div
                  {...chestAnomalyDropzone.getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input {...chestAnomalyDropzone.getInputProps()} />
                  {chestAnomalyDropzone.isDragActive ? (
                    <p className="drag-drop-file-text">در حال کشیدن...</p>
                  ) : (
                    <p className="manual-upload">{/* انتخاب دستی کارنامه */}</p>
                  )}
                  <button className="upload-button-home">
                    انتخاب دستی{" "}
                    <AiOutlineFileText
                      className="react-icons-upload"
                      color="#333"
                      title="آپلود فایل"
                    />
                  </button>
                  <p className="drag-drop-file-text">
                    یا می توانید فایل را در این محل رها نمایید
                  </p>
                  {chestAnomalyUploadProgress > 0 && (
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${chestAnomalyUploadProgress}%` }}
                      ></div>
                    </div>
                  )}
                </div>
              )}

            {chestAnomalyFile &&
              !chestAnomalyPdfUrl &&
              !isChestAnomalyLoading &&
              !chestAnomalyPdfUrl && (
                <div className="dropzone">
                  <p className="drag-drop-file-text-smaller-center">
                    آپلود شد!
                  </p>
                </div>
              )}
            {!chestAnomalyPdfUrl &&
              canTakeTestAnomaly &&
              !isChestAnomalyLoading &&
              !chestAnomalyPdfUrl && (
                <button className="edit-button" onClick={handleSubmitChestXray}>
                  تحلیل کن!
                </button>
              )}

            {/* {!chestAnomalyPdfUrl && !canTakeTestAnomaly && !isChestAnomalyLoading && !chestAnomalyPdfUrl && <button className="edit-button disabled">
                          تحلیل کن!
                      </button>} */}

            {remainingTestsAnomaly !== null &&
              !isChestAnomalyLoading &&
              !chestAnomalyPdfUrl && (
                <div className="test-info">
                  <p className="test-count">
                    تعداد آزمایش‌های باقی‌مانده:{" "}
                    <span className="test-count-number">
                      {remainingTestsAnomaly}
                    </span>{" "}
                    از{" "}
                    <span className="test-total-number">
                      {anomalyCapacityTests}
                    </span>
                  </p>
                </div>
              )}
            {remainingTestsAnomaly != 0 &&
              lastTestDateAnomaly &&
              !isChestAnomalyLoading &&
              !chestAnomalyPdfUrl && (
                <div className="test-info">
                  <p className="last-test-date">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    آخرین آزمایش:{" "}
                    <span className="test-date">
                      {formattedLastTestDateAnomaly}
                    </span>
                  </p>
                </div>
              )}
            {remainingTestsAnomaly === 0 &&
            !isChestAnomalyLoading &&
            !chestAnomalyPdfUrl ? (
              <div className="test-message test-full">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                <p>
                  شما دیگر نمی‌توانید آزمایشی انجام دهید. ظرفیت شما تمام شده
                  است.
                </p>
              </div>
            ) : (
              canTakeTestAnomaly !== null &&
              !isChestAnomalyLoading &&
              !chestAnomalyPdfUrl && (
                <div
                  className={`test-message ${
                    canTakeTestAnomaly ? "test-available" : "test-unavailable"
                  }`}
                >
                  {canTakeTestAnomaly ? (
                    <p>
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                      شما می‌توانید آزمایش جدیدی انجام دهید.
                    </p>
                  ) : (
                    <p>
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                      شما نمی‌توانید در حال حاضر آزمایشی انجام دهید. لطفاً فردا
                      دوباره امتحان کنید.
                    </p>
                  )}
                </div>
              )
            )}

            {isChestAnomalyLoading && (
              <div>
                <p>لطفاً منتظر نتیجه باشید...</p>
              </div>
            )}

            {chestAnomalyPdfUrl && (
              <div>
                <img
                  src={chestAnomalyPdfUrl}
                  alt="Chest X-Ray Analysis Result"
                  className="chest-result-image"
                  onClick={openChestModal} // Open modal on click
                />
                <a
                  href={chestAnomalyPdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  دانلود نتیجه
                </a>
              </div>
            )}

            {canTakeTestAnomaly != null && !canTakeTestAnomaly && (
              <div>
                {!paymentUrl && (
                  <button
                    className={`payment-button ${
                      isLoadingPayment ? "loading" : ""
                    }`}
                    onClick={() => handlePayment("anomaly")}
                    disabled={isLoadingPayment}
                  >
                    {isLoadingPayment ? (
                      <span className="spinner"></span>
                    ) : (
                      "افزایش ظرفیت - 45 هزار تومان"
                    )}
                  </button>
                )}

                {paymentUrl && (
                  <button
                    className="payment-button"
                    onClick={() => (window.location.href = paymentUrl)}
                  >
                    تکمیل پرداخت
                  </button>
                )}
              </div>
            )}

            {isModalChestOpen && (
              <div className="chest-modal-overlay" onClick={closeChestModal}>
                <div
                  className="chest-modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={chestAnomalyPdfUrl}
                    alt="Enlarged Chest X-Ray"
                    className="chest-modal-image"
                  />
                  <button
                    className="chest-close-button"
                    onClick={closeChestModal}
                  >
                    &times;
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="box">
                  <h2 className="box-title">یادت نره!</h2>
                  <div className="reminder-content">
                    <div className="coming-soon-message">
                      <h4>به زودی!</h4>
                      <p className='reminder-description'>
                        این ویژگی به شما یادآوری می‌کند که داروهای خود را به موقع مصرف کنید. با زنگ هشدار مخصوص، دیگر فراموش نخواهید کرد!
                      </p>
                    </div>
                  </div>
                </div> */}

        <div className="box">
          <h2 className="box-title">یادت نره!</h2>
          <div className="reminder-list">
            {/* <h3 className='reminder-list-h3'>لیست یادآوری ها</h3> */}
            <div>
              {reminders.length === 0 ? (
                <p className="reminder-list-p">
                  برای اضافه کردن یادآوری جدید از دکمه اضافه کردن استفاده کنید.
                </p>
              ) : (
                reminders.map((reminder) => (
                  <div key={reminder.id} className="reminder-item">
                    <p>
                      <strong>نام دارو&nbsp;</strong>&nbsp;{" "}
                      {reminder.medicine_name}
                    </p>
                    <p>
                      <strong>مقدار&nbsp;</strong>&nbsp;{" "}
                      {reminder.dosage_quantity_of_units_per_time}{" "}
                      {dosageUnitTranslations[
                        reminder.dosage_unit_of_measure
                      ] || reminder.dosage_unit_of_measure}
                    </p>
                    <p>
                      <strong>تعداد دفعات در روز&nbsp;</strong>&nbsp;{" "}
                      {reminder.dosage_frequency} بار در روز
                    </p>
                    <p>
                      <strong>طریقه مصرف&nbsp;</strong>&nbsp;{" "}
                      {routeOfAdministrationTranslations[
                        reminder.route_of_administration
                      ] || reminder.route_of_administration}
                    </p>
                    <p>
                      <strong>تاریخ شروع&nbsp;</strong>&nbsp;{" "}
                      {formatJalaliDate(reminder.first_time_of_intake)}
                    </p>
                    <p>
                      <strong>تاریخ توقف&nbsp;</strong>&nbsp;{" "}
                      {formatJalaliDate(reminder.stopped_by_datetime)}
                    </p>
                    {/* <p><strong>تاریخ بعدی&nbsp;</strong>&nbsp; {formatJalaliDate(reminder.next_dose_time)}</p> */}
                    <p>
                      <strong>باقیمانده تا دوز بعدی&nbsp;</strong>&nbsp;{" "}
                      {timeLeft[reminder.id]}
                    </p>
                    <p>
                      <strong>یادآوری برای&nbsp;</strong>&nbsp;
                      {reminder.recipient_type === "self" && "خودتان"}
                      {reminder.recipient_type === "salmand" && "سالمند شما"}
                      {reminder.recipient_type === "salamatyar" &&
                        "سلامت‌یار شما"}
                    </p>
                    {/* <p><strong>فرم دوز:</strong> {dosageFormTranslations[reminder.dosage_form] || reminder.dosage_form}</p> */}
                    {/* {reminder.regimen_note && <p><strong>یادداشت:</strong> {reminder.regimen_note}</p>} */}
                    {/* {reminder.is_chronic_or_acute !== null && <p><strong>نوع درمان:</strong> {reminder.is_chronic_or_acute ? 'مزمن' : 'حاد'}</p>} */}
                    <div className="reminder-list-button-div">
                      <button
                        className="delete-button-reminder"
                        title="حذف"
                        onClick={() => handleDeleteRequest(reminder.id)}
                      >
                        <FontAwesomeIcon
                          className="fa-sm"
                          icon={faTrashAlt}
                          color="white"
                        />
                      </button>
                      <button
                        className="edit-icon-reminder"
                        title="ویرایش"
                        onClick={() => handleEditClick(reminder)}
                      >
                        <FontAwesomeIcon
                          className="fa-sm"
                          icon={faPenToSquare}
                          color="white"
                        />
                      </button>
                    </div>
                  </div>
                ))
              )}
              {showDeleteConfirmation && (
                <DeleteConfirmation
                  handleConfirm={handleConfirmDelete}
                  handleCancel={handleCancelDelete}
                />
              )}
              {isEditModalOpen && selectedReminder && (
                <EditReminderForm
                  reminder={selectedReminder}
                  handleClose={handleClose}
                  handleUpdate={handleUpdate}
                />
              )}
            </div>
          </div>

          {canTakeMedication && (
            <button
              className="add-reminder-button"
              onClick={() => setIsModalOpen(true)}
            >
              افزودن{" "}
              <FontAwesomeIcon className="fa-sm" icon={faPlus} color="white" />
            </button>
          )}

          {remainingMedication !== null && (
            <div className="test-info">
              <p className="test-count">
                ظرفیت باقی‌مانده:{" "}
                <span className="test-count-number">{remainingMedication}</span>{" "}
                از{" "}
                <span className="test-total-number">{medicationCapacity}</span>
              </p>
            </div>
          )}
          {remainingMedication === 0 ? (
            <div className="test-message test-full">
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              <p>
                شما دیگر نمی‌توانید دارویی اضافه کنید. ظرفیت شما تمام شده است.
              </p>
            </div>
          ) : (
            ""
          )}

          {canTakeMedication != null && !canTakeMedication && (
            <div>
              {!paymentUrl && (
                <button
                  className={`payment-button ${
                    isLoadingPayment ? "loading" : ""
                  }`}
                  onClick={() => handlePayment("medication")}
                  disabled={isLoadingPayment}
                >
                  {isLoadingPayment ? (
                    <span className="spinner"></span>
                  ) : (
                    "افزایش ظرفیت - 35 هزار تومان"
                  )}
                </button>
              )}

              {paymentUrl && (
                <button
                  className="payment-button"
                  onClick={() => (window.location.href = paymentUrl)}
                >
                  تکمیل پرداخت
                </button>
              )}
            </div>
          )}

          {isModalOpen && stepReminder == 1 && (
            <div className="modal choosing">
              <div className="modal-content choosing">
                {/* <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span> */}
                <div className="xmark-font-awsome-right">
                  <button className="submit-filter-but">
                    <FontAwesomeIcon
                      onClick={() => setIsModalOpen(false)}
                      className="fontawesome-icon fa-2xl"
                      icon={faXmark}
                      color=""
                    />
                  </button>
                </div>
                <div className="recipient-selection-container">
                  <h2>برای چه کسی یادآوری می‌خواهید؟</h2>
                  <div className="recipient-options">
                    {/* Self Option */}
                    <div
                      className={`recipient-box ${
                        selectedRecipient === "self" ? "selected" : ""
                      }`}
                      onClick={() => handleRecipientSelection("self")}
                    >
                      <FontAwesomeIcon icon={faUser} size="3x" />
                      <h3>خودم</h3>
                    </div>
                    {(userInfo.user_type == "سلامتیار" ||
                      userInfo.user_type == "User") && (
                      <div
                        className={`recipient-box ${
                          selectedRecipient === "salmand" ? "selected" : ""
                        }`}
                        onClick={() => handleRecipientSelection("salmand")}
                      >
                        <FontAwesomeIcon icon={faUserNurse} size="3x" />
                        <h3>والدینم</h3>
                      </div>
                    )}

                    {userInfo.user_type == "سالمند" && (
                      <div
                        className={`recipient-box ${
                          selectedRecipient === "salamatyar" ? "selected" : ""
                        }`}
                        onClick={() => handleRecipientSelection("salamatyar")}
                      >
                        <FontAwesomeIcon icon={faUserMd} size="3x" />
                        <h3>فرزندم</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isModalOpen && stepReminder == 2 && (
            <div className="modal">
              <div className="modal-content">
                {/* <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span> */}
                <div className="xmark-font-awsome-right">
                  <button className="submit-filter-but">
                    <FontAwesomeIcon
                      onClick={() => setIsModalOpen(false)}
                      className="fontawesome-icon fa-2xl"
                      icon={faXmark}
                      color=""
                    />
                  </button>
                </div>
                {
                  <div>
                    <form onSubmit={addReminder} className="medication-form">
                      <div className="form-group">
                        <label>
                          نام دارو{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faPills}
                            color="red"
                          />
                        </label>
                        <input
                          type="text"
                          value={formData.medicine_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              medicine_name: e.target.value,
                            })
                          }
                        />
                        {errors.medicine_name && (
                          <p className="error">{errors.medicine_name}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          روش مصرف{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faSyringe}
                            color="red"
                          />
                        </label>
                        <select
                          value={formData.route_of_administration}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              route_of_administration: e.target.value,
                            })
                          }
                        >
                          <option value="">انتخاب کنید</option>
                          <option value="oral">خوراکی</option>
                          <option value="parentral/im">تزریق عضلانی</option>
                          <option value="parentral/sc">تزریق زیرجلدی</option>
                          <option value="parentral/iv">تزریق وریدی</option>
                        </select>
                        {errors.route_of_administration && (
                          <p className="error">
                            {errors.route_of_administration}
                          </p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          شکل دارو{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faPrescriptionBottleAlt}
                            color="red"
                          />
                        </label>
                        <select
                          value={formData.dosage_form}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dosage_form: e.target.value,
                            })
                          }
                        >
                          <option value="">انتخاب کنید</option>
                          <option value="tablet">قرص</option>
                          <option value="capsule">کپسول</option>
                          <option value="syrup">شربت</option>
                          <option value="injectable">تزریقی</option>
                        </select>
                        {errors.dosage_form && (
                          <p className="error">{errors.dosage_form}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          واحد اندازه گیری دوز{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faBalanceScale}
                            color="red"
                          />
                        </label>
                        <select
                          value={formData.dosage_unit_of_measure}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dosage_unit_of_measure: e.target.value,
                            })
                          }
                        >
                          <option selected disabled value="">
                            لطفاً انتخاب کنید...
                          </option>
                          <option value="tablet">قرص</option>
                          <option value="capsule">کپسول</option>
                          <option value="gravimetric/mg">میلی‌گرم/mg</option>
                          <option value="gravimetric/iu">
                            واحد بین‌المللی/iu
                          </option>
                          <option value="volumetric/ml">میلی‌لیتر/ml</option>
                        </select>
                        {errors.dosage_unit_of_measure && (
                          <p className="error">
                            {errors.dosage_unit_of_measure}
                          </p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          بازه زمانی{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faStopwatch}
                            color="red"
                          />
                        </label>
                        <select
                          value={formData.periodic_interval}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              periodic_interval: e.target.value,
                            })
                          }
                        >
                          <option selected disabled value="">
                            لطفاً انتخاب کنید...
                          </option>
                          <option value="daily">روزانه</option>
                          <option value="weekly">هفتگی</option>
                          <option value="monthly">ماهانه</option>
                        </select>
                        {errors.periodic_interval && (
                          <p className="error">{errors.periodic_interval}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          تعداد دفعات مصرف در روز{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faArrowUp91}
                            color="red"
                          />
                        </label>
                        <input
                          type="number"
                          pattern="\d*"
                          placeholder="مثلاً 1"
                          step="1"
                          min="1"
                          max="6"
                          value={formData.dosage_frequency}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (value <= 6) {
                              setFormData({
                                ...formData,
                                dosage_frequency: e.target.value,
                              });
                            }
                          }}
                        />
                        {errors.dosage_frequency && (
                          <p className="error">{errors.dosage_frequency}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          مقدار واحدهای دوز در هر بار{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faListNumeric}
                            color="red"
                          />
                        </label>
                        <input
                          type="number"
                          pattern="\d*"
                          placeholder="مثلاً 2.5"
                          min="0.1"
                          step="0.1"
                          value={formData.dosage_quantity_of_units_per_time}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dosage_quantity_of_units_per_time: e.target.value,
                            })
                          }
                        />
                        {errors.dosage_quantity_of_units_per_time && (
                          <p className="error">
                            {errors.dosage_quantity_of_units_per_time}
                          </p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          تاریخ شروع{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faCalendarAlt}
                            color="red"
                          />
                        </label>

                        <DatePicker
                          value={formData.first_time_of_intake}
                          onChange={(selectedDate) => {
                            setFormData({
                              ...formData,
                              first_time_of_intake: selectedDate,
                            });
                          }}
                          locale="fa"
                          inputPlaceholder="تاریخ را انتخاب کنید"
                          shouldHighlightWeekends
                          calendarClassName="date-picker-calendar"
                          wrapperClassName="date-picker-wrapper"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            // label="زمان شروع"
                            value={formData.intake_time}
                            onChange={(newTime) =>
                              setFormData({ ...formData, intake_time: newTime })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            ampm={false}
                          />
                        </LocalizationProvider>
                        {errors.first_time_of_intake && (
                          <p className="error">{errors.first_time_of_intake}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          مدت مصرف{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faTablets}
                            color="red"
                          />
                        </label>
                        <select
                          value={formData.is_chronic_or_acute}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              is_chronic_or_acute: e.target.value,
                            })
                          }
                        >
                          <option value="true">بلند مدت</option>
                          <option value="false">کوتاه مدت</option>
                        </select>
                        {errors.is_chronic_or_acute && (
                          <p className="error">{errors.is_chronic_or_acute}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          تاریخ توقف{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faCalendarAlt}
                            color="red"
                          />
                        </label>
                        <DatePicker
                          value={formData.stopped_by_datetime}
                          onChange={(selectedDate) => {
                            setFormData({
                              ...formData,
                              stopped_by_datetime: selectedDate,
                            });
                          }}
                          locale="fa"
                          inputPlaceholder="تاریخ را انتخاب کنید"
                          shouldHighlightWeekends
                          calendarClassName="date-picker-calendar"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            // label="زمان شروع"
                            value={formData.stopped_time}
                            onChange={(newTime) =>
                              setFormData({
                                ...formData,
                                stopped_time: newTime,
                              })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            ampm={false}
                          />
                        </LocalizationProvider>
                        {errors.stopped_by_datetime && (
                          <p className="error">{errors.stopped_by_datetime}</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          توضیحات{" "}
                          <FontAwesomeIcon
                            className="fa-sm"
                            icon={faComment}
                            color="red"
                          />
                        </label>
                        <textarea
                          value={formData.regimen_note}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              regimen_note: e.target.value,
                            })
                          }
                        ></textarea>
                        {errors.regimen_note && (
                          <p className="error">{errors.regimen_note}</p>
                        )}
                      </div>
                    </form>
                    <button onClick={addReminder} className="submit-reminder">
                      ثبت
                    </button>
                  </div>
                }
              </div>
            </div>
          )}
        </div>

        <div className="box">
          <h2 className="box-title">برنامه تمرینی و غذایی هوشمند</h2>
          <div className="chatbox__support">
            <div className="chatbox__header">
              <h4 className="chatbox__heading--header">چت بات</h4>
              <p className="chatbox__description"></p>
            </div>
            <div className="chatbot-messages" id="food-chatbot-messages">
              <div className="coming-soon-message">
                <h4>به زودی!</h4>
                <p className="coming-soon-chat">
                  چت بات هوشمند به زودی در دسترس قرار خواهد گرفت!
                </p>
              </div>
            </div>
            <div className="chatbox__footer">
              <input
                type="text"
                className="chatbox__input"
                placeholder="برنامه غذایی و تمرینی میخوای؟"
                // value={inputValue}
                // onChange={handleInputChange}
              />
              <button disabled={true} className="chatbox__send--footer">
                ارسال
              </button>
            </div>
          </div>
        </div>
        <div className="box">
          <h2 className="box-title">چت بات عمومی سلامت</h2>
          <div className="chatbox__support">
            <div className="chatbox__header">
              <h4 className="chatbox__heading--header">چت بات</h4>
              <p className="chatbox__description"></p>
            </div>
            <div className="chatbot-messages" id="food-chatbot-messages">
              <div className="coming-soon-message">
                <h4>به زودی!</h4>
                <p className="coming-soon-chat">
                  چت بات هوشمند به زودی در دسترس قرار خواهد گرفت!
                </p>
              </div>
            </div>
            <div className="chatbox__footer">
              <input
                type="text"
                className="chatbox__input"
                placeholder="هرچه دل تنگت میخواهد بگو!"
                // value={inputValue}
                // onChange={handleInputChange}
              />
              <button disabled={true} className="chatbox__send--footer">
                ارسال
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

const Menu = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [userInfoLoaded, setUserInfoLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [message, setMessage] = useState(""); // State to manage success or error messages
  const [messageType, setMessageType] = useState(""); // State to manage message type (success/error)

  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setSelectedImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setIsLoading(true);
    axios
      .delete(`${API}api/user/remove_profile_picture/`, {
        headers: {
          Authorization: "Token " + Cookies.get("token"),
        },
      })
      .then((response) => {
        setMessage("عکس حذف شد!");
        setMessageType("success");
        loadUserInfo(); // Refresh to show default image
        setTimeout(() => {
          setMessage("");
          setMessageType("");
        }, 2000);
      })
      .catch((error) => {
        setMessage("حذف عکس ناموفق بود. دوباره تلاش کنید!");
        setMessageType("error");
        setTimeout(() => {
          setMessage("");
          setMessageType("");
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleImageUpload = () => {
    if (!selectedImage) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("profile_image", selectedImage);

    axios
      .put(`${API}api/user/update_profile_picture/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Token " + Cookies.get("token"),
        },
      })
      .then((response) => {
        setMessage("عکس تغییر کرد!");
        setMessageType("success"); // Set message type to success
        loadUserInfo(); // Refresh user info to show the updated image
        setTimeout(() => {
          setMessage("");
          setMessageType("");
        }, 2000);
        setSelectedImage(null);
      })
      .catch((error) => {
        setMessage("لطفاً دوباره امتحان کنید!");
        setMessageType("error"); // Set message type to error
        setTimeout(() => {
          setMessage("");
          setMessageType("");
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the upload completes
      });
  };

  const loadUserInfo = () => {
    getUserInfo().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setUserInfo(data);
        setUserInfoLoaded(true);
      }
    });
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  return (
    <div>
      <ul>
        <div
          className="profile-image-container"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <img
            className="profile-image"
            src={
              previewImage ||
              (userInfo.image_url
                ? userInfo.image_url
                : require("../images/no-profile.png"))
            }
            alt="Profile"
          />
          {isHovering && (
            <div className="camera-icon-container">
              <label htmlFor="image-upload" className="camera-icon">
                {/* <img src={require('../images/no-profile.png')} alt="Upload" /> */}
                <FontAwesomeIcon
                  className="fontawesome-icon-image fa-xl"
                  icon={faImage}
                  color="white"
                />
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
          )}
        </div>
        <div>
          {selectedImage && !isLoading && (
            <button className="save-button" onClick={handleImageUpload}>
              آپلود عکس
            </button>
          )}

          {userInfo.image_url && !isLoading && (
            <button className="remove-button" onClick={handleRemoveImage}>
              حذف عکس
            </button>
          )}
        </div>

        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
          </div>
        )}

        {message && (
          <p
            className={`message ${
              messageType === "success" ? "message-success" : "message-error"
            }`}
          >
            {message}
          </p>
        )}
        <p className="profile-name">{userInfo.full_name}</p>
        <p className="profile-number">{userInfo.user_type}</p>
      </ul>
      <div class="payment-footer">
        <button
          className="group-payment-btn"
          onClick={() => (window.location.href = "/select-quantity")} // Redirect to group payment
        >
          افزایش ظرفیت (گروهی)
        </button>
      </div>
    </div>
  );
};

const ProfileForm = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [originalUserInfo, setOriginalUserInfo] = useState({});
  const [userInfoLoaded, setUserInfoLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const loadUserInfo = () => {
    getUserInfo().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setUserInfo(data);
        setOriginalUserInfo(data);
        setUserInfoLoaded(true);
      }
    });
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (!validateEmail(userInfo.email)) {
      setError("لطفاً ایمیل معتبری را وارد کنید!");
      setTimeout(() => setError(""), 3000); // Hide after 2 seconds
      return; // Don't send the API request if email is invalid
    }
    updateUserInfo(userInfo)
      .then(() => {
        setIsEditing(false);
        setSuccessMessage("اطلاعات شما آپدیت شد!");
        setOriginalUserInfo(userInfo);
        setTimeout(() => setSuccessMessage(""), 3000); // Hide after 1 second
      })
      .catch((error) => {
        // Display the specific error message
        if (error.message == "This email is already in use.") {
          setError("ایمیل وارد شده استفاده شده!");
        } else {
          setError("مشکلی پیش آمد. لطفاً دوباره امتحان کنید!");
        }
        setTimeout(() => setError(""), 3000); // Hide after 1 second
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCancelClick = () => {
    setIsEditing(false); // Exit editing mode
    setUserInfo(originalUserInfo); // Revert back to original user info
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const updateUserInfo = async (userInfo) => {
    try {
      const response = await fetch(`${API}api/user/update_user_profile/`, {
        method: "PUT",
        headers: {
          Authorization: "Token " + Cookies.get("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update profile");
      }

      return await response.json();
    } catch (error) {
      throw new Error(error.message || "Error updating profile");
    }
  };

  return (
    <div>
      <div className="navbar-div-profile">
        <BlogNavbar />
      </div>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {error && <p className="error-message">{error}</p>}
      {isEditing ? (
        <div className="profile-form">
          <div className="form-group">
            <label>نام</label>
            <input
              type="text"
              name="first_name"
              value={userInfo.first_name || ""}
              onChange={handleChange}
              className="input-field"
              placeholder="Enter your first name"
            />
          </div>
          <div className="form-group">
            <label>نام خانوادگی</label>
            <input
              type="text"
              name="last_name"
              value={userInfo.last_name || ""}
              onChange={handleChange}
              className="input-field"
              placeholder="Enter your last name"
            />
          </div>
          <div className="form-group">
            <label>ایمیل</label>
            <input
              type="email"
              name="email"
              value={userInfo.email || ""}
              onChange={handleChange}
              className="input-field"
              placeholder="Enter your email"
            />
          </div>
          <div className="button-group">
            <button onClick={handleCancelClick} className="cancel-button">
              لغو
            </button>
            <button onClick={handleSaveClick} className="save-button">
              اصلاح
            </button>
          </div>
        </div>
      ) : (
        <div className="profile-info">
          <div className="info-item">
            <label className="label-prof">نام:</label>
            <span className="span-prof">{userInfo.first_name}</span>
          </div>
          <div className="info-item">
            <label className="label-prof">نام خانوادگی:</label>
            <span className="span-prof">{userInfo.last_name}</span>
          </div>
          <div className="info-item">
            <label className="label-prof">ایمیل:</label>
            <span className="span-prof">{userInfo.email}</span>
          </div>
          <button onClick={handleEditClick} className="edit-button">
            ویرایش
          </button>
        </div>
      )}
    </div>
  );
};
